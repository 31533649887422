import { ColorsType } from '~/theme/model/themeModel';
import { defineCustomTextColors } from '~/theme/utils/getTextColor';
import * as colorsBase from './colorsBase';

export const palette = {
	black: '#222222',
	white: '#FEFEFE',

	orange100: '#E9E9E9',
	orange40: '#EABAA5',

	green100: '#5D7975',
	green40: '#BEC9C8',
	green20: '#DFE4E3',

	blue100: '#28334A',
	blue40: '#A9ADB7',
	blue20: '#D4D6DB',

	burntRed100: '#874B52',
	burntRed40: '#CFB7BA',
	burntRed20: '#E7DBDC',

	okker100: '#B9AB97',
	okker40: '#E3DDD5',
	okker20: '#F1EEEA',

	sand100: '#D6C9CA',
	sand40: '#EFE9EA',
	sand20: '#F7F4F4',

	productCard: '#F7F7F5',
};

// Override automatic text color assignment (which works 90% of the time) and
// assign custom text colors to specific theme colors.
defineCustomTextColors({
	[palette.orange100]: 'white',
	[palette.okker100]: 'black',
});

export const colorsKay: ColorsType = {
	...colorsBase,
	black: palette.black,
	white: palette.white,

	productPickerBorder: '#A99C96',

	primary: palette.orange100,
	secondary: palette.okker100,

	buttonDark: palette.black,
	buttonLight: palette.white,

	productDetailsBackground: palette.sand20,

	productCardThumbnail: palette.productCard,

	pageLoadingColor: palette.burntRed40,

	metaMenuDefaultBackgroundColor: palette.sand20,
};
