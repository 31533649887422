import { ThemeType } from '../../model/themeModel';
import { breakpoints } from '../breakpoints';
import { colorsBw } from '../colors/colorsBw';
import { getFluidFontSize } from '../getClamp';
import { themeBase } from './themeBase';

const fonts = {
	headline: 'Spartan, Arial, sans-serif',
	body: 'STIX Two Text, Georgia, serif',
	mono: 'STIX Two Text, Georgia, serif', // TODO: Figure out how we support mono texts for brands without mono fonts.
};

export const fontWeights = {
	light: 300,
	regular: 400,
	medium: 500,
	semiBold: 600,
	bold: 700,
};

const lineHeights = {
	...themeBase.lineHeights,
	headline: '1.43333333',
	headlineTall: '1.5',
	body: '1.66666667',
	base: '1.75',
	medium: '1.48',
	compact: '1.25',
};

const bodyStyles: Record<any, any> = {
	fontFamily: fonts.body,
	lineHeight: lineHeights.body,
	fontWeight: fontWeights.regular,
};

const headlineStyles: Record<any, any> = {
	fontFamily: fonts.headline,
	textTransform: 'uppercase',
};

const { textStyles } = themeBase;

/**
 * Bjørn Wiinblad theme
 */
export const themeBw: ThemeType = {
	...themeBase,
	id: 'bw',
	fonts,
	lineHeights,
	colors: colorsBw,
	textStyles: {
		...textStyles,
		body: {
			...bodyStyles,
			fontSize: getFluidFontSize(18, 20),
			letterSpacing: '0.02777778em',
		},
		bodySm: {
			...bodyStyles,
			lineHeight: lineHeights.base,
			fontSize: getFluidFontSize(16, 18),
			letterSpacing: '0.01875em',
		},
		bodyXs: {
			...bodyStyles,
			lineHeight: '1.42857143',
			letterSpacing: '0.01428571em',
			fontSize: getFluidFontSize(14, 16),
		},
		label: {
			...bodyStyles,
			fontSize: getFluidFontSize(16, 17),
			lineHeight: lineHeights.single,
			letterSpacing: '0.01875em',
		},
		display1: {
			...headlineStyles,
			lineHeight: lineHeights.headlineTall,
			fontWeight: fontWeights.semiBold,
			fontSize: '35px',
			letterSpacing: '0.3px',

			[breakpoints.sm]: {
				fontSize: '50px',
				letterSpacing: '8.5px',
			},
			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(60, 66),
				letterSpacing: '0.16666667em',
			},
		},
		display2: {
			...headlineStyles,
			lineHeight: lineHeights.headlineTall,
			fontWeight: fontWeights.semiBold,
			fontSize: '30px',
			letterSpacing: '5px',

			[breakpoints.sm]: {
				fontSize: '35px',
				letterSpacing: '6px',
			},
			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(40, 46),
				letterSpacing: '0.175em',
			},
		},
		display3: {
			...headlineStyles,
			lineHeight: lineHeights.headline,
			fontWeight: fontWeights.semiBold,
			fontSize: '25px',
			letterSpacing: '4px',

			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(30, 36),
				letterSpacing: '0.16666667em',
			},
		},
		display3Push: {
			...headlineStyles,
			lineHeight: lineHeights.medium,
			fontWeight: fontWeights.bold,
			fontSize: '22px',
			letterSpacing: '3.5px',

			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(25),
				letterSpacing: '0.16em',
			},
		},
		display4: {
			...headlineStyles,
			lineHeight: lineHeights.headline,
			fontWeight: fontWeights.semiBold,
			fontSize: '20px',
			letterSpacing: '4px',

			[breakpoints.lg]: {
				fontSize: getFluidFontSize(25),
				letterSpacing: '0.16666667em',
			},
		} as Record<any, any>,
		display5: {
			...headlineStyles,
			lineHeight: lineHeights.headline,
			fontWeight: fontWeights.semiBold,
			fontSize: '18px',
			letterSpacing: '3px',

			[breakpoints.lg]: {
				fontSize: getFluidFontSize(20),
				letterSpacing: '0.16666667em',
			},
		} as Record<any, any>,
	},
	button: {
		...themeBase.button,
		fontFamily: fonts.body,
		fontWeight: fontWeights.medium,
		fontSize: '14px',
		fontSizeSmall: '10px',
		letterSpacing: 'normal',
	},
};
