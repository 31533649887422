import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { DehydratedState, Hydrate } from 'react-query/hydration';

/**
 * Find default query options as
 * `QueryObserverOptions` at https://github.com/tannerlinsley/react-query/blob/master/src/core/types.ts
 */
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			// Disabled since we don't rely on realtime data
			refetchOnWindowFocus: false,

			// Time before revalidating data. Set to 5min to
			// match reactQuery's default cache time
			staleTime: 3000,
		},
	},
});

export const RequestProvider = ({ children, dehydratedState }: { children: ReactNode; dehydratedState: DehydratedState | null }) => (
	<QueryClientProvider client={queryClient}><Hydrate state={dehydratedState}>{children}</Hydrate></QueryClientProvider>
);
