import { ThemeType } from '../../model/themeModel';
import { breakpoints } from '../breakpoints';
import { colorsKay } from '../colors/colorsKay';
import { getFluidFontSize } from '../getClamp';
import { themeBase } from './themeBase';

export const fontSizes = {
	'4xs': getFluidFontSize(13, 15),
	'3xs': getFluidFontSize(14, 16),
	'2xs': getFluidFontSize(15, 17),
	xs: getFluidFontSize(16, 18),
	sm: getFluidFontSize(18, 20),
	md: getFluidFontSize(20),
	lg: getFluidFontSize(22),
	xl: getFluidFontSize(27),
	'2xl': getFluidFontSize(32),
	'3xl': getFluidFontSize(35),
	'4xl': getFluidFontSize(40),
	'5xl': getFluidFontSize(50),
	'6xl': getFluidFontSize(60),
	'7xl': getFluidFontSize(70),
};

const fonts = {
	headline: 'Barlow Condensed, Arial, sans-serif',
	body: 'Barlow Condensed, Arial, sans-serif',
	mono: 'Barlow Condensed, Arial, sans-serif',
};

export const fontWeights = {
	light: 300,
	regular: 400,
	medium: 500,
	semiBold: 600,
	bold: 700,
};

const lineHeights = {
	single: '1',
	compact: '1.15',
	base: '1.6',
	body: '1.54545455',
	headline: '1.14285714',
	medium: '1.2',
	headlineTall: '1.25',
};

const bodyStyles: Record<any, any> = {
	fontFamily: fonts.body,
	lineHeight: lineHeights.body,
	fontWeight: fontWeights.regular,
};

const headlineStyles: Record<any, any> = {
	fontFamily: fonts.headline,
	lineHeight: lineHeights.headline,
	textTransform: 'uppercase',
	fontWeight: fontWeights.semiBold,
};

/**
 * Kay Bojesen theme
 */
export const themeKay: ThemeType = {
	...themeBase,
	id: 'kay',
	fonts,
	fontSizes,
	fontWeights,
	lineHeights,
	colors: colorsKay,
	productDetails: {
		...themeBase.productDetails,
		carousel: {
			...themeBase.productDetails.carousel,
			paginationType: 'line',
		},
	},
	textStyles: {
		...themeBase.textStyles,
		body: {
			...bodyStyles,
			fontSize: getFluidFontSize(22, 24),
		},
		bodySm: {
			...bodyStyles,
			fontSize: getFluidFontSize(20, 22),
		},
		bodyXs: {
			...bodyStyles,
			fontSize: getFluidFontSize(18, 20),
		},
		label: {
			...bodyStyles,
			fontWeight: fontWeights.medium,
			fontSize: getFluidFontSize(16, 18),
		},
		display1: {
			...headlineStyles,
			fontSize: '45px',
			letterSpacing: '1.3px',

			[breakpoints.sm]: {
				fontSize: '60px',
				letterSpacing: '1.8px',
			},
			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(70),
				letterSpacing: '0.02857143em', // Should equal 2px on desktop breakpoint
			},
		},
		display2: {
			...headlineStyles,
			lineHeight: lineHeights.medium,
			fontSize: '40px',
			letterSpacing: '1.16px',

			[breakpoints.sm]: {
				fontSize: '45px',
				letterSpacing: '1.3px',
			},
			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(50),
				letterSpacing: '0.03em', // Should equal 1.5px on desktop breakpoint
			},
		},
		display3: {
			...headlineStyles,
			lineHeight: lineHeights.headlineTall,
			fontSize: '35px',
			letterSpacing: '1px',

			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(40),
				letterSpacing: '0.03em', // Should equal 1.2px on desktop breakpoint
			},
		},
		display3Push: {
			...headlineStyles,
			lineHeight: lineHeights.headlineTall,
			fontSize: '30px',
			letterSpacing: '0.8px',

			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(35),
				letterSpacing: '0.02857143em', // Should equal 1px on desktop breakpoint
			},
		},
		display4: {
			...headlineStyles,
			lineHeight: lineHeights.headlineTall,
			fontSize: '30px',
			letterSpacing: '0.8px',

			[breakpoints.lg]: {
				fontSize: getFluidFontSize(35),
				letterSpacing: '0.03em',
			},
		},
		display5: {
			...headlineStyles,
			lineHeight: lineHeights.headlineTall,
			fontSize: '28px',
			letterSpacing: '0.5px',

			[breakpoints.lg]: {
				fontSize: getFluidFontSize(30),
				letterSpacing: '0.03em',
			},
		},
	},
	button: {
		...themeBase.button,
		fontFamily: fonts.body,
		fontSize: getFluidFontSize(16, 18),
		fontSizeSmall: getFluidFontSize(12, 15),
	},
};
