import { detect } from 'detect-browser';

declare global {
	interface Navigator {
		msMaxTouchPoints: number;
	}
}

export const isSSR = typeof window === 'undefined';
export const isBrowser = !isSSR;
export const isDevelopment = process.env.NODE_ENV === 'development';
export const isProduction = !isDevelopment;

export const isSafariOrIOS = (() => {
	if (isSSR) {
		return false;
	}

	const result = detect();

	if (result && (result.name === 'safari' || result.os === 'iOS')) {
		return true;
	}

	return false;
})();

export const isMobileDevice = (() => {
	if (isSSR) {
		return false;
	}

	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
})();

export const isTouchDevice = (() => {
	if (isSSR) {
		return false;
	}

	return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
})();
