/* eslint-disable @next/next/no-before-interactive-script-outside-document */

import { useRouter } from 'next/router';
import Script from 'next/script';
import { useUpdateEffect } from 'react-use';
import { GOOGLE_TRACKING_ID } from '~/features/tracking/constants';
import { useFrame } from '~/shared/utils/frame/hooks/useFrame';
import { isSSR } from '~/shared/utils/platform/utils/platform';

const disablingScriptQuery = 'rdg-flags-disable-cookieinformation';

/**
 * Updates culture of the script of cookie consent
 * @param culture ISO 639-1 but upper case (Ex: DA, EN). Invalid values will fall back to default set in CookieInformation website
 */
const changeCookieInformationCulture = (culture: string) => {
	// Cookie consent script can't be removed to change language, but there is a helper method to reload
	// So it changes the culture attribute and calls that third party script method
	// https://support.cookieinformation.com/en/articles/5432277-single-page-applications-spa
	window.document.getElementById('CookieConsent')?.setAttribute('data-culture', culture);
	(window as any).CookieInformation?.loadConsent?.();
};

/**
 * Loads cookie consent, and reloads in the correct language if culture changes
 */
export const CookieConsent = () => {
	const {
		data: { culture: cultureCMS },
	} = useFrame();
	const router = useRouter();
	const disabled = router.asPath.includes(disablingScriptQuery);
	const culture = cultureCMS.toLocaleUpperCase(); // CookieInformation expects it in capital case

	useUpdateEffect(() => {
		changeCookieInformationCulture(culture);
	}, [culture]);

	if (disabled) {
		return null;
	}

	if (!isSSR) {
		if (window.location.hostname === 'localhost' || window.location.hostname === 'local') {
			return null;
		}
	}

	/**
	 * TagManagerScript and TraceDockScript are loaded with dangerouslyInsetHtml attr,
	 * this is loaded with src attr which makes NextJS render/load this script AFTER TagManagerScript and TraceDockScript.
	 * This is the desired order.
	 */
	return (
		<>
			<Script
				id="CookieConsentAdvancedGTagInitiator"
				strategy="beforeInteractive"
				type="text/javascript"
				dangerouslySetInnerHTML={{
					__html: `
						window.dataLayer = window.dataLayer || [];
						
						function gtag() {
							dataLayer.push(arguments);
						}
						
						gtag(
							'consent',
							'default',
							{
								'ad_storage': 'denied',
								'ad_user_data': 'denied',
								'ad_personalization': 'denied',
								'analytics_storage': 'denied',
								'wait_for_update': 500,
							}
						);
						
						gtag('set', 'ads_data_redaction', true);
					`,
				}}
			/>

			<Script
				id="gtm-script"
				dangerouslySetInnerHTML={{
					__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${GOOGLE_TRACKING_ID}');`,
				}}
			/>

			<Script
				id="CookieConsent"
				strategy="beforeInteractive"
				type="text/javascript"
				src="https://policy.app.cookieinformation.com/uc.js"
				data-culture={culture} /** Note that changing the prop will not automatically reload the script */
				data-gcm-version="2.0"
			/>
		</>
	);
};
