import { ThemeType } from '../../model/themeModel';
import { breakpoints } from '../breakpoints';
import { colorsRos } from '../colors/colorsRos';
import { getFluidFontSize } from '../getClamp';
import { themeBase } from './themeBase';

const fonts = {
	headline: 'Poppins, Arial, sans-serif',
	body: 'Poppins, Arial, sans-serif',
	mono: 'Poppins, Arial, sans-serif',
};

export const fontWeights = {
	light: 300,
	regular: 400,
	medium: 500,
	semiBold: 500,
	bold: 700,
};

export const fontSizes = {
	'4xs': getFluidFontSize(8, 10),
	'3xs': getFluidFontSize(10, 12),
	'2xs': getFluidFontSize(12, 14),
	xs: getFluidFontSize(14, 16),
	sm: getFluidFontSize(16, 18),
	md: getFluidFontSize(18),
	lg: getFluidFontSize(20),
	xl: getFluidFontSize(25),
	'2xl': getFluidFontSize(30),
	'3xl': getFluidFontSize(40),
	'4xl': getFluidFontSize(50),
	'5xl': getFluidFontSize(56),
	'6xl': getFluidFontSize(60),
	'7xl': getFluidFontSize(66),
};

const lineHeights = {
	...themeBase.lineHeights,
	headline: '1.1',
	headlineTall: '1.2',
	body: '1.66666667',
};

const bodyStyles: Record<any, any> = {
	fontFamily: fonts.body,
};

const headlineStyles: Record<any, any> = {
	fontFamily: fonts.headline,
	lineHeight: lineHeights.headline,
	textTransform: 'uppercase',
};

/**
 * Rosendahl theme
 */
export const themeRos: ThemeType = {
	...themeBase,
	id: 'ros',
	fonts,
	lineHeights,
	colors: colorsRos,
	textStyles: {
		...themeBase.textStyles,
		body: {
			...bodyStyles,
			lineHeight: lineHeights.body,
			fontSize: getFluidFontSize(18, 20),
		},
		bodySm: {
			...bodyStyles,
			lineHeight: '1.75',
			fontSize: getFluidFontSize(16, 18),
		},
		bodyXs: {
			...bodyStyles,
			lineHeight: '1.42857143',
			fontSize: getFluidFontSize(14, 16),
		},
		label: {
			fontFamily: fonts.body,
			fontSize: getFluidFontSize(14, 16),
			letterSpacing: '1px',
			lineHeight: '1',
			textTransform: 'none',
		},
		display1: {
			fontFamily: headlineStyles.fontFamily,
			lineHeight: lineHeights.headline,
			fontWeight: 'normal',
			textTransform: 'uppercase',
			fontSize: '35px',
			letterSpacing: '5px',

			[breakpoints.sm]: {
				fontSize: '50px',
				letterSpacing: '6px',
			},
			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(60),
				letterSpacing: '0.11666667em', // Should equal 7px on desktop breakpoint
			},
		},
		display2: {
			fontFamily: headlineStyles.fontFamily,
			lineHeight: lineHeights.headline,
			fontWeight: 'normal',
			textTransform: 'uppercase',
			fontSize: '30px',
			letterSpacing: '4px',

			[breakpoints.sm]: {
				fontSize: '35px',
				letterSpacing: '5px',
			},
			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(40),
				letterSpacing: '0.125em', // Should equal 5px on desktop breakpoint
			},
		},
		display3: {
			fontFamily: headlineStyles.fontFamily,
			lineHeight: lineHeights.headlineTall,
			fontWeight: 'normal',
			textTransform: 'uppercase',
			fontSize: '30px',
			letterSpacing: '3px',

			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(30),
				letterSpacing: '0.13333333em', // Should equal 4px on desktop breakpoint
			},
		},
		display3Push: {
			fontFamily: headlineStyles.fontFamily,
			lineHeight: lineHeights.headlineTall,
			fontWeight: fontWeights.medium,
			textTransform: 'uppercase',
			fontSize: getFluidFontSize(25, 29),
			letterSpacing: '0.5px',

			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				letterSpacing: '0.12em', // Should equal 3px on desktop breakpoint
			},
		},
		display4: {
			fontFamily: headlineStyles.fontFamily,
			lineHeight: lineHeights.headlineTall,
			fontWeight: fontWeights.medium,
			textTransform: 'uppercase',
			fontSize: '20px',
			letterSpacing: '2px',

			[breakpoints.lg]: {
				fontSize: getFluidFontSize(25),
				letterSpacing: '0.14em',
			},
		},
		display5: {
			fontFamily: headlineStyles.fontFamily,
			lineHeight: lineHeights.headlineTall,
			fontWeight: fontWeights.medium,
			textTransform: 'uppercase',
			fontSize: '18px',
			letterSpacing: '1px',

			[breakpoints.lg]: {
				fontSize: getFluidFontSize(20),
				letterSpacing: '0.15em',
			},
		},
	},
	button: {
		...themeBase.button,
		fontFamily: fonts.body,
		fontWeight: fontWeights.medium,
		letterSpacing: '0.03125em',
		textTransform: 'none',
	},
	fontSizes,
	fontWeights,
};
