import styled from '@emotion/styled';
import { CSSProperties } from 'react';
import { ifProp } from 'styled-tools';
import { StyledHeadline } from '~/shared/components/Headline/styled';
import { StyledText } from '~/shared/components/Text/styled';

export type StyledRichTextProps = {
	className?: string;
	maxWidth?: CSSProperties['maxWidth'];
};

export const StyledPAsDiv = styled.div(({ theme }) => ({
	marginBottom: theme.space[3],
}));

export const StyledRichText = styled.div<StyledRichTextProps>(
	({ theme }) => ({
		isolation: 'isolate', // Elevate z-index to be able to draw Kähler brush strokes beneath text

		img: {
			width: '100%',
			height: 'auto',
		},

		[`p, ol, ul, pre, table, ${StyledHeadline}`]: {
			marginBottom: theme.space[3],
		},

		p: {
			minHeight: '1em',
		},

		a: {
			marginBottom: theme.space[2],
		},

		li: {
			marginBottom: theme.space[2],
		},

		ol: {
			paddingLeft: 25,
			listStyle: 'decimal',
		},

		ul: {
			paddingLeft: 25,
			listStyle: 'disc',
		},

		code: {
			fontFamily: theme.fonts.mono,
		},

		pre: {
			backgroundColor: theme.colors.grey20,
			padding: 15,
			fontFamily: theme.fonts.mono,
		},

		[`${StyledHeadline}`]: {
			':not(:first-of-type)': {
				marginTop: '0.75em',
			},
		},
	}),
	ifProp('maxWidth', (props) => ({
		maxWidth: props.maxWidth,
	})),
);

export const StyledTable = styled(StyledText.withComponent('table'))(({ theme }) => ({
	minWidth: '100%',
	borderCollapse: 'collapse',

	// Tables
	'td, th': {
		padding: '0.75em',
		textAlign: 'left',
		border: `1px solid ${theme.colors.grey30}`,
		boxSizing: 'border-box',
	},

	th: {
		fontWeight: theme.fontWeights.bold,
		backgroundColor: theme.colors.grey20,
		fontSize: theme.fontSizes.xs,
	},

	thead: {
		position: 'sticky',
		top: 0,
		backgroundColor: '#fff',
	},

	'tbody > tr > th:first-of-type': {
		position: 'sticky',
		left: 0,
	},
}));
