import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import { ShouldNotForwardProps } from '~/shared/utils/styled/shouldNotForwardProps';
import { HeadlineProps } from './Headline';

export const StyledHeadline = styled('h1', {
	shouldForwardProp: ShouldNotForwardProps(['size']),
})<HeadlineProps>(
	({ theme }) => ({
		color: theme.colors.black,
		fontFamily: theme.fonts.headline,
		fontWeight: theme.fontWeights.regular,
	}),
	ifProp('alignment', ({ alignment }) => ({
		textAlign: alignment,
	})),
	switchProp('size', {
		'1': ({ theme }) => ({
			...theme.textStyles.display1,
		}),
		'2': ({ theme }) => ({
			...theme.textStyles.display2,
		}),
		'3': ({ theme }) => ({
			...theme.textStyles.display3,
		}),
		'4': ({ theme }) => ({
			...theme.textStyles.display4,
		}),
		'5': ({ theme }) => ({
			...theme.textStyles.display5,
		}),
		'6': ({ theme }) => ({
			...theme.textStyles.display6,
		}),
	}),
);
