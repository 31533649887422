import { transparentize } from 'color2k';

// Core colors
export const blackMeta = '#222222';
export const black = blackMeta;
export const white = '#FEFEFE';
export const pureWhite = '#FFFFFF';
export const transparent = '#FFFFFF00';
export const dark = black;
export const light = '#FEFEFE';

// Greys (also for cross-brand elements - do not override in theme)
export const grey60 = '#919192';
export const grey50 = '#AEAEAF';
export const grey40 = '#D1D3D4';
export const grey30 = '#E2E3E4';
export const grey20 = '#F1F2F2';
export const grey10 = '#F7F8F8';
export const grey = grey50;

// Generic color mappings
export const primary = black;
export const secondary = light;

export const positive = '#6BAEA4';
export const neutral = grey;
export const negative = '#AB5C57';
export const border = grey40;

// Button
export const buttonDark = black;
export const buttonLight = white;

// Product card
export const productCardThumbnail = '#F7F7F5'; // "META Product Card" token in design
export const productCardPrice = black;
export const productCardPriceOld = transparentize(black, 0.5);
export const productCardDiscount = '#F73F3F';
export const productCardLabel = grey60;
export const productCardShipmentLabel = grey50;

// Product details
export const productDetailsBackground = '#F9F9F9';
export const productDetailsCarouselPaginationBg = grey50;
export const productDetailsCarouselPaginationBgActive = black;

// Loading overlay
export const loadingOverlay = transparentize(white, 0.5);

// PDP
export const discountPrice = productCardDiscount;
export const oldPrice = '#929192';
export const productPickerBorder = grey50;
export const productPickerBorderActive = black;

// Default body text color
export const bodyText = black;

export const bodyTextLight = white;

// Default body background color
export const bodyBackground = pureWhite;

// Navigation
export const navigationBackground = light;
export const navigationBorder = grey30;

// Checkout
export const checkoutHeaderBackground = '#F4F3EE';

// Badges
export const salesBadgeBackground = black;
export const salesBadgeText = white;
export const normalBadgeForeground = '#929192';
export const normalBadgeBackground = '#F7F7F5';

// Voucher
export const addedVoucherBackground = '#E5E2D4';

// Maps
export const mapsLoadingColor = grey30;

export const pageLoadingColor = '#666666';

export const metaMenuDefaultBackgroundColor = '#F9F9F9';
