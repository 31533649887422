import { css, Global } from '@emotion/react';
import emotionReset from 'emotion-reset';
import { breakpoints } from '~/theme/lib/breakpoints';
import { bodyBackground } from '~/theme/lib/colors/colorsBase';
import { columnGlobals } from '~/theme/lib/grid';
import { fonts, globalCSSVariables } from '~/theme/lib/themes/themeBase';
import { fontFace } from '~/theme/typography/font-faces';

export const GlobalStyles = () => (
	<Global
		styles={css`
			${emotionReset}
			${columnGlobals}
			${fontFace}

			html {
				${globalCSSVariables}

				height: 100%;
				box-sizing: border-box;
				hyphens: auto;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}

			*,
			*:before,
			*:after {
				box-sizing: inherit;
			}

			body {
				line-height: 1.75; // TODO: This is just a dummy value for now.
				font-family: ${fonts.body};
				height: 100%;
				margin: 0;
				overflow-y: scroll;
				background-color: ${bodyBackground};
			}

			html,
			body {
				height: 100%;

				#dixa-iframe-wrapper > #dixa-widget-wrapper {
					z-index: 200 !important;

					${breakpoints.xs} {
						transform: scale(0.66);
					}
				}
			}
			
			html.noScroll {
				&, body {
					overflow: hidden;
				}
			}

			input:-webkit-autofill,
			input:-webkit-autofill:hover,
			input:-webkit-autofill:focus,
			input:-webkit-autofill:active {
				-webkit-box-shadow: 0 0 0 30px #f7f8f8 inset !important;
			}
		`}
	/>
);
