import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { useMemo } from 'react';
import { useFrame } from '~/shared/utils/frame/hooks/useFrame';
import { themes } from '../lib/themes';

export const ThemeProvider = (props: { children: React.ReactNode }) => {
	const { data: frame } = useFrame();
	const brandNo = frame.vertical?.brandNo || 'none';
	const theme = useMemo(() => {
		return themes[brandNo];
	}, [brandNo]);
	return (
		<EmotionThemeProvider
			theme={theme}
			{...props}
		/>
	);
};
