import { createContext, ReactNode } from 'react';
import { IPages } from '~/lib/data-contract';

type PageProviderProps = {
	children: ReactNode;
} & PageProviderContext;

type PageProviderContext = {
	page: IPages;
};

export const PageContext = createContext<PageProviderContext>({
	page: {} as IPages,
});

export const PageProvider = ({ children, ...value }: PageProviderProps) => {
	return (
		<PageContext.Provider
			value={value}
			children={children}
		/>
	);
};
