declare global {
	interface Window {
		dataLayer: Record<string, any>[];
	}
}

export const pushToDataLayer = <DataType extends Record<string, any>>(data: DataType) => {
	// we want to make sure that dataLayer is not undefined (storybook)
	if ('dataLayer' in window) {
		window.dataLayer.push(data);
	}
};
