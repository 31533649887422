import { css, Interpolation } from '@emotion/react';
import { CSSProperties } from 'react';
import { ThemeIds } from '~/theme/model/themeModel';
import * as baseAnimations from '../animations/baseAnimations';
import { breakpoints, mediaQueries } from '../breakpoints';
import * as colorsBase from '../colors/colorsBase';
import { getFluidFontSize } from '../getClamp';

/**
 * Global CSS variables
 *
 * This is useful for setting up any global reactive variables. Read more:
 * https://www.joshwcomeau.com/css/css-variables-for-react-devs/#changing-values-not-variables
 */
export const globalCSSVariables = css({
	'--top-menu-height': '68px',
	'--navigation-pane-width': 'clamp(300px, 80%, 480px)',
	'--icon-stroke-thickness': '1',
	'--row-gap': 'var(--grid-row)',
	'--brand-logo-width': '80px',

	[breakpoints.md]: {
		'--brand-logo-width': '100px',
	},

	[breakpoints.lg]: {
		'--navigation-pane-width': '280px',
		'--top-menu-height': '117px',
	},

	[mediaQueries.highDevicePixelRatio]: {
		'--icon-stroke-thickness': '1.25',
	},
});

const zIndices = {
	'50': 50,
	'100': 100,
	'125': 125,
	'150': 150,
	'175': 175,
	'200': 200,
	'300': 300,
	'400': 400,
	'500': 500,
	max: 999,
};

const id: ThemeIds = 'none';

/**
 * Variables for column grid layout
 */
export const gridConfig = {
	columns: {
		xs: 4,
		md: 12,
	},
	// Rows are identical to columns, but cap at a max size. Useful for 2-dimensional layouts.
	rows: {
		xs: '80px',
		md: '100px',
	},
	gutter: {
		xs: '20px',
		md: '30px',
	},
};

export const fonts = {
	headline: "'DM Sans', Helvetica, Arial, sans-serif",
	body: "'DM Sans', Helvetica, Arial, sans-serif",
	mono: 'monospace',
};

export const fontSizes = {
	'4xs': getFluidFontSize(8, 10),
	'3xs': getFluidFontSize(10, 12),
	'2xs': getFluidFontSize(12, 14),
	xs: getFluidFontSize(14, 16),
	sm: getFluidFontSize(16, 18),
	md: getFluidFontSize(18),
	lg: getFluidFontSize(20),
	xl: getFluidFontSize(25),
	'2xl': getFluidFontSize(30),
	'3xl': getFluidFontSize(35),
	'4xl': getFluidFontSize(40),
	'5xl': getFluidFontSize(45),
	'6xl': getFluidFontSize(50),
	'7xl': getFluidFontSize(55),
};

export const fontWeights = {
	light: 300,
	regular: 400,
	medium: 500,
	semiBold: 600,
	bold: 700,
};

export const lineHeights = {
	single: '1',
	compact: '1.15',
	base: '1.666', // According to the design we should go with fontSize 18px and lineheigh 30px = 30/18 = 1,66666667
	body: '1.66666667',
	headline: '1.1',
	headlineTall: '1.2',
	medium: '1.35',
};
export const letterSpacing = {
	xs: '1px',
	md: '3px',
	lg: '5px',
};

const bodyStyles: Record<any, any> = {
	fontFamily: fonts.body,
	lineHeight: lineHeights.body,
	fontWeight: fontWeights.regular,
};

const headlineStyles: Record<any, any> = {
	fontFamily: fonts.headline,
	lineHeight: lineHeights.headline,
	fontWeight: fontWeights.bold,
};

/**
 * Base theme. Any property not overwritten in a brand theme will use the base theme.
 * ThemeType is inferred from the baseTheme const definition
 */
export const themeBase = {
	id: id as ThemeIds,
	colors: colorsBase as Record<keyof typeof colorsBase, string>,
	animations: {
		...baseAnimations,
	},
	fonts,
	fontSizes,
	fontWeights,
	lineHeights,
	letterSpacing,
	textStyles: {
		body: {
			...bodyStyles,
			fontSize: getFluidFontSize(18, 20),
		} as Record<any, any>,
		bodySm: {
			...bodyStyles,
			fontSize: getFluidFontSize(16, 18),
		} as Record<any, any>,
		bodyXs: {
			...bodyStyles,
			fontSize: getFluidFontSize(14, 16),
		} as Record<any, any>,
		label: {
			fontFamily: fonts.body,
			fontWeight: fontWeights.medium,
			lineHeight: lineHeights.single,
			fontSize: getFluidFontSize(16, 18),

			[breakpoints.md]: {
				fontSize: fontSizes['2xs'],
			},
		} as Record<any, any>,
		// TODO: Add the right sizes, breakpoints etc when RDG is done
		display1: {
			...headlineStyles,
			fontSize: '35px',

			[breakpoints.sm]: {
				fontSize: '50px',
			},

			[breakpoints.lg]: {
				fontSize: getFluidFontSize(60, 68),
			},
		} as Record<any, any>,
		display2: {
			...headlineStyles,
			fontSize: '30px',

			[breakpoints.sm]: {
				fontSize: '35px',
			},

			[breakpoints.lg]: {
				fontSize: getFluidFontSize(40),
			},
		} as Record<any, any>,
		display3: {
			...headlineStyles,
			fontSize: '25px',

			[breakpoints.lg]: {
				fontSize: getFluidFontSize(30),
			},
		} as Record<any, any>,
		display3Push: {
			...headlineStyles,
			fontSize: getFluidFontSize(25),
		} as Record<any, any>,
		display4: {
			...headlineStyles,
			fontSize: '20px',

			[breakpoints.lg]: {
				fontSize: getFluidFontSize(25),
			},
		} as Record<any, any>,
		display5: {
			...headlineStyles,
			fontSize: '18px',

			[breakpoints.lg]: {
				fontSize: getFluidFontSize(20),
			},
		} as Record<any, any>,
		display6: {
			...headlineStyles,
			fontSize: '15px',

			[breakpoints.lg]: {
				fontSize: getFluidFontSize(18),
			},
		} as Record<any, any>,
	},
	space: {
		'1': '5px',
		'2': '10px',
		'3': '15px',
		'4': '20px',
		'5': '30px',
		'6': '40px',
		'7': '50px',
		'8': '60px',
		'9': '70px',
		'10': '80px',
		gutter: gridConfig.gutter,
		rowGap: 'var(--row-gap)',
		blendingTopPadding: {
			xs: '120px',
			md: '150px',
		},
		topMenuHeight: 'var(--top-menu-height)',
		mainNavigationTopOffset: '40px',
		letters: {
			lg: '4px',
		},
		brandOverview: {
			gutter: {
				sm: '15px',
				lg: '30px',
			},
			padding: {
				sm: '25px',
			},
			textLinkLetterSpacing: '1px',
		},
		dialog: {
			width: {
				xs: '100%',
				sm: 'clamp(443px, 32.4vw, 622px)',
			},
			topOffset: {
				xs: '20vh',
				sm: 0,
			},
			topPadding: {
				xs: '76px',
				sm: 'clamp(80px, 22vh, 140px)',
			},
		},
		forms: {
			verticalGap: '20px',
			horizontalGap: '10px',
		},
	},
	sizes: {
		contentMaxWidth: '1440px',
		content: 'min(88vw, 1690px)',
		iconXs: '20px',
		iconSm: '25px',
		iconMd: '30px',
		iconLg: '35px',
		iconXl: '45px',
		icon2XL: '55px',
		icon3xl: '130px',
		iconStrokeWidth: 1,
		iconStrokeWidthHighDPI: 1.25,
	},
	grid: gridConfig,
	zIndices: {
		brandOverlay: zIndices['200'],
		mainMenu: zIndices['200'],
		content: zIndices['100'],
		contentLoadingOverlay: zIndices['150'],
		productCardBadges: zIndices['100'],
		productCardLink: zIndices['150'],
		productCardVariants: zIndices['175'],
		productCardWishlist: zIndices['175'],
		mobileNavigation: zIndices['300'],
		mobileNavigationToggle: zIndices['400'],
		newsletterOverlay: zIndices['400'],
		newsletterContent: zIndices['max'],
		notifications: zIndices['max'],
		facetsOverlay: zIndices['300'],
		dialogOverlay: zIndices['500'],
		dialogFooter: zIndices['100'],
		galleryDialog: zIndices['max'],
		scrollToTopButton: zIndices['200'],
	},
	button: {
		height: '50px',
		heightSmall: '34px',
		fontSize: fontSizes.xs,
		fontSizeSmall: fontSizes['3xs'],
		fontFamily: fonts.body,
		fontWeight: fontWeights.medium,
		letterSpacing: letterSpacing.xs,
		textTransform: 'uppercase' as CSSProperties['textTransform'],
	},
	forms: {
		height: {
			default: '58px',
			compact: '50px',
			tiny: '40px',
		},
		border: '1px',
		textColor: colorsBase.black,
		textColorDimmed: colorsBase.grey60,
		textColorInactive: colorsBase.grey50,
		disabledLines: colorsBase.grey30,
		labelScaleDown: 0.875,
		selectorOptionHeight: '48px',
		selectorPopoverMinHeight: '264px', // 5.5 views of options
		selectorPopoverCollisionPadding: -200,
		selectorOptionHighlightBackground: colorsBase.grey20,
		selectorOptionCurrentBackground: colorsBase.grey30,
	},
	productCard: {
		cardsPerRow: {
			xs: 2,
			sm: 3,
			lg: 4,
		},
		imageWidthNumber: 340,
		imageHeightNumber: 425,
		carouselWidthXs: 2.2,
		carouselWidthLg: 3.2,
		carouselWidthXl: 4,
		zIndexBrandLabel: zIndices['100'],
	},
	productDetails: {
		top: {
			paddingBottom: {
				xs: '40px',
				md: 'clamp(40px, 6vh, 80px)',
			},
			minHeight: {
				xs: 'auto',
				md: 'clamp(620px, calc(100vh - var(--grid-row) * 3), 1080px)',
			},
		},
		carousel: {
			paginationType: 'circle' as 'circle' | 'outline' | 'line',
			slideOpacity: 0,
		},
		aside: {
			maxWidth: '425px',
		},
		stamp: {
			size: getFluidFontSize(80, 96),
			zIndex: zIndices[50],
		},
	},
	categoryImageWidthNumber: 76,
	categoryImageWidth: '76px',
	checkboxSize: '20px',
	checkboxBorderWidth: '1px',
	iconBlocks: {
		gutter: '100px',
	},
	textMedia: {
		canvasOffsetBottom: '100px',
		imageRatios: {
			portrait: 336 / 420,
			landscape: 623 / 415,
		},
	},
	carousel: {
		gradientWidth: '50px',
		gradientHeight: 'calc(100% + 4px)',
		gradientBg: 'linear-gradient(var(--carosuel-gradient-degree), #FFFFFF 2px, rgba(255,255,255,0) 100%)',
	},
	categorySpotGutter: 'var(--grid-gutter)',
	textColumnsGutter: '30px',
	boxShadow: '8px 8px 14px 0 rgba(0,0,0,0.15)',
	navigation: {
		textTransform: 'none' as CSSProperties['textTransform'],
	},
};

export type BaseThemeType = typeof themeBase;
export type CSSProp = Interpolation<BaseThemeType>;
