import { Interpolation } from '@emotion/react';

export const smMinWidth = 600;
export const mdMinWidth = 1024;
export const lgMinWidth = 1367;
export const xlMinWidth = 1920;

export const xsCondition = '(max-width: 599px)';
export const smCondition = `(min-width: ${smMinWidth}px)`;
export const mdCondition = `(min-width: ${mdMinWidth}px)`;
export const lgCondition = `(min-width: ${lgMinWidth}px)`;
export const xlCondition = `(min-width: ${xlMinWidth}px)`;

export const breakpoints = {
	xs: `@media ${xsCondition}`, // Mobile
	sm: `@media ${smCondition}`, // Small tablets
	md: `@media ${mdCondition}`, // Large tablets, small desktops
	lg: `@media ${lgCondition}`, // Desktops
	xl: `@media ${xlCondition}`,
};

export const mediaQueries = {
	...breakpoints,
	reducedMotion: '@media (prefers-reduced-motion)',
	highDevicePixelRatio: `@media only screen and (-moz-min-device-pixel-ratio: 2),
                only screen and (-o-min-device-pixel-ratio: 2/1),
                only screen and (-webkit-min-device-pixel-ratio: 2),
                only screen and (min-device-pixel-ratio: 2)`,
};

export const onEachBreakpoint = (breakpointHandler: (breakpointKey: string, breakpointCondition: string) => Interpolation<any>) =>
	Object.entries(breakpoints).reduce(
		(obj, [key, condition]) => {
			obj[condition] = breakpointHandler(key, condition);
			return obj;
		},
		{} as { [key: string]: Interpolation<any> },
	);
