import { ThemeType } from '../../model/themeModel';
import { breakpoints } from '../breakpoints';
import { colorsLyp } from '../colors/colorsLyp';
import { getFluidFontSize } from '../getClamp';
import { themeBase } from './themeBase';

const fonts = {
	headline: 'EB Garamond, Georgia, serif',
	body: 'EB Garamond, Georgia, serif',
	mono: 'EB Garamond, Georgia, serif',
};
export const fontWeights = {
	light: 300,
	regular: 400,
	medium: 500,
	semiBold: 600,
	bold: 700,
};

const lineHeights = {
	...themeBase.lineHeights,
	headline: '1.1',
	headlineTall: '1.2',
	body: '1.66666667',
	base: '1.75',
};

const bodyStyles: Record<any, any> = {
	fontFamily: fonts.body,
	lineHeight: lineHeights.body,
	fontWeight: themeBase.fontWeights.medium,
};

const headlineStyles: Record<any, any> = {
	fontFamily: fonts.headline,
	lineHeight: lineHeights.headline,
	fontWeight: themeBase.fontWeights.medium,
	textTransform: 'uppercase',
};

/**
 * Lyngby Porcelæn theme
 */
export const themeLyp: ThemeType = {
	...themeBase,
	id: 'lyp',
	colors: colorsLyp,
	fonts,
	lineHeights,
	fontWeights,
	textStyles: {
		...themeBase.textStyles,
		body: {
			...bodyStyles,
			fontSize: getFluidFontSize(18, 20),
		},
		bodySm: {
			...bodyStyles,
			fontSize: getFluidFontSize(16, 18),
		},
		bodyXs: {
			...bodyStyles,
			lineHeight: '1.42857143',
			fontSize: getFluidFontSize(14, 16),
		},
		label: {
			...bodyStyles,
			fontSize: getFluidFontSize(16, 17),
			lineHeight: lineHeights.single,
		},
		display1: {
			...headlineStyles,
			fontSize: '35px',
			letterSpacing: '1.1px',

			[breakpoints.sm]: {
				fontSize: '50px',
				letterSpacing: '1.6px',
			},
			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(60, 66),
				letterSpacing: '0.03166667em',
			},
		},
		display2: {
			...headlineStyles,
			fontSize: '30px',
			letterSpacing: '1px',

			[breakpoints.sm]: {
				fontSize: '35px',
				letterSpacing: '1.1px',
			},
			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(40, 46),
				letterSpacing: '0.0325em',
			},
		},
		display3: {
			...headlineStyles,
			fontSize: '25px',
			letterSpacing: '0.8px',

			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(30, 36),
				letterSpacing: '0.03333333em',
			},
		},
		display3Push: {
			...headlineStyles,
			fontWeight: fontWeights.bold,
			fontSize: getFluidFontSize(25, 29),
			letterSpacing: '0.032em',
		},
		display4: {
			...headlineStyles,
			fontSize: '20px',
			letterSpacing: '0.8px',

			[breakpoints.lg]: {
				fontSize: getFluidFontSize(25),
				letterSpacing: '0.035em',
			},
		},
		display5: {
			...headlineStyles,
			fontSize: '18px',
			letterSpacing: '0.5px',

			[breakpoints.lg]: {
				fontSize: getFluidFontSize(20),
				letterSpacing: '0.04em',
			},
		},
	},
	button: {
		...themeBase.button,
		fontFamily: fonts.body,
		fontWeight: fontWeights.medium,
		fontSize: getFluidFontSize(16, 18),
		fontSizeSmall: getFluidFontSize(12, 15),
		letterSpacing: '0.03125em',
		textTransform: 'none',
	},
};
