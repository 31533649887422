import { useRouter } from 'next/router';
import { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { isSSR } from '../../platform';

type RouteTransitionProps = { loading: boolean };

export const RouteTransitionContext = createContext<RouteTransitionProps>({ loading: false });

export const RouteTransitionProvider = ({ children }: { children: ReactNode }) => {
	const router = useRouter();
	const [loading, setLoading] = useState(false);

	const handleStart = useCallback(() => {
		(document.activeElement as any)?.blur?.(); // If there was a button as link clicked, force lose focus
		setLoading(true);
	}, []);

	const handleComplete = useCallback(() => {
		setLoading(false);
	}, []);

	useEffect(() => {
		if (isSSR) {
			return;
		}

		router.events.on('routeChangeStart', handleStart);
		router.events.on('routeChangeComplete', handleComplete);
		router.events.on('routeChangeError', handleComplete);

		return () => {
			router.events.off('routeChangeStart', handleStart);
			router.events.off('routeChangeComplete', handleComplete);
			router.events.off('routeChangeError', handleComplete);
		};
	}, []);

	return <RouteTransitionContext.Provider value={{ loading }}>{children}</RouteTransitionContext.Provider>;
};
