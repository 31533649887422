import { ThemeIds, ThemeType } from '../model/themeModel';

import { themeAj } from './themes/themeAj';
import { themeBase } from './themes/themeBase';
import { themeBw } from './themes/themeBw';
import { themeGlo } from './themes/themeGlo';
import { themeHo } from './themes/themeHo';
import { themeJun } from './themes/themeJun';
import { themeKah } from './themes/themeKah';
import { themeKay } from './themes/themeKay';
import { themeLyp } from './themes/themeLyp';
import { themeRos } from './themes/themeRos';

/** Import locally hosted fonts */

export const themes: Record<ThemeIds, ThemeType> = {
	none: themeBase,
	aj: themeAj,
	bw: themeBw,
	glo: themeGlo,
	ho: themeHo,
	jun: themeJun,
	kah: themeKah,
	kay: themeKay,
	lyp: themeLyp,
	ros: themeRos,
};
