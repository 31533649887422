import styled from '@emotion/styled';
import { Icon } from '~/shared/components/Icon/Icon';
import { breakpoints } from '~/theme/lib/breakpoints';

interface StyledToTopButtonProps {
	isVisible?: boolean;
}

export const StyledToTopButton = styled(Icon)<StyledToTopButtonProps>(({ theme, isVisible }) => ({
	position: 'fixed',
	opacity: isVisible ? 1 : 0,
	transform: `translateY(${isVisible ? 0 : '100%'})`,
	transition: `opacity ${theme.animations.timingMedium} ${theme.animations.easeSmooth},
		transform ${theme.animations.timingLonger} ${theme.animations.easeSmooth}`,

	width: '60px',
	height: '60px',
	margin: '20px',
	right: '80px',
	bottom: 0,
	borderRadius: '50%',
	backgroundColor: 'white',
	fontSize: '3rem',
	boxShadow: 'rgb(0 0 0 / 15%) 0px 0px 10px 0px',
	cursor: 'pointer',
	zIndex: theme.zIndices.scrollToTopButton,

	[breakpoints.xs]: {
		transform: `translateY(${isVisible ? 0 : '100%'}) scale(0.66)`,
		right: '55px',
		margin: 'auto',
	},
}));
