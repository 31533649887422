import { MotionValue, useMotionValue, useSpring } from 'framer-motion';
import { createContext, useEffect } from 'react';

type UiProps = {
	cursor: {
		x: MotionValue<number>;
		y: MotionValue<number>;
		trailingX: MotionValue<number>;
		trailingY: MotionValue<number>;
	};
};

export const UiContext = createContext<UiProps>({
	cursor: {
		x: new MotionValue(0),
		y: new MotionValue(0),
		trailingX: new MotionValue(0),
		trailingY: new MotionValue(0),
	},
});

/**
 * The UiProvider is used to provide the UiContext to all components.
 *
 * The UiContext holds any information that is needed to render the UI. This includes
 * the cursor position, which is used to render the cursor. Properties like this are
 * preferable to store using motion values, as they do not trigger re-renders when
 * the value changes.
 */
export const UiProvider = ({ children }: { children: React.ReactNode }) => {
	const cursorX = useMotionValue(0);
	const cursorY = useMotionValue(0);
	const cursorTrailingX = useSpring(cursorX, {
		stiffness: 500,
		damping: 50,
		mass: 0.2,
	});
	const cursorTrailingY = useSpring(cursorY, {
		stiffness: 500,
		damping: 50,
		mass: 0.2,
	});

	const handleMouseMove = (event: MouseEvent) => {
		cursorX.set(event.clientX);
		cursorY.set(event.clientY);
	};

	useEffect(() => {
		window.addEventListener('mousemove', handleMouseMove);

		return () => {
			window.removeEventListener('mousemove', handleMouseMove);
		};
	}, []);

	return (
		<UiContext.Provider
			value={{
				cursor: {
					x: cursorX,
					y: cursorY,
					trailingX: cursorTrailingX,
					trailingY: cursorTrailingY,
				},
			}}
		>
			{children}
		</UiContext.Provider>
	);
};
