import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { IFrame } from '~/lib/data-contract';
import { isSSR } from '~/shared/utils/platform/utils/platform';
import { useGetRequest } from '~/shared/utils/request/hooks/useGetRequest';
import { usePage } from '~/templates/pages/hooks/usePage';
import { requestDefaults } from '../../request/utils/requestDefaults';
import { getFrameQueryKey } from '../utils/getFrameQueryKey';

let previousFrame: IFrame;

/**
 * Frame hook (top menu, footer, settings, and other things that are not page-specific).
 * Will load initial data provided by context on initial load, and fetch new values when vertical id, culture or market changes
 * Always try to provide a value, even if it's from the previously loaded frame, but the isLoading will indicate
 * if it's serving stale data
 */
export const useFrame = () => {
	const router = useRouter();
	const { verticalId, culture, market } = usePage();
	const queryKey = getFrameQueryKey(culture, market, verticalId);

	const { data } = useGetRequest<IFrame>('/api/proxy/content/frame', {
		params: { url: router.asPath },
		queryKey,
		keepPreviousData: true,
		staleTime: requestDefaults.cacheTime, // Never stale until cache expires
		cacheTime: requestDefaults.cacheTime,
		enabled: !isSSR,
	});

	useEffect(() => {
		if (data && previousFrame !== data) {
			previousFrame = data;
		}
	}, [data]);

	const dataOrPrevious = data ?? previousFrame;
	const isChangingVertical = isSSR ? false : dataOrPrevious.vertical?.id !== verticalId;
	return useMemo(
		() => ({
			data: dataOrPrevious,
			isChangingVertical,
		}),
		[dataOrPrevious.id, dataOrPrevious.culture, dataOrPrevious.market, isChangingVertical],
	);
};
