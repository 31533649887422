import { ColorsType } from '~/theme/model/themeModel';
import { defineCustomTextColors } from '~/theme/utils/getTextColor';
import * as colorsBase from './colorsBase';

// TBD: This is the temporary colors until AJ colors ar properly defined.
export const palette = {
	black: '#222222',
	white: '#FEFEFE',

	black85: '#262626',
	black70: '#676767',

	cement100: '#9C9680',
	cement50: '#CDCAC6',
	cement25: '#E6E5E2',

	sand100: '#D0CBBB',
	sand50: '#E7E5DD',
	sand25: '#F3F2EE',

	red100: '#C11729',
	red50: '#E08B94',
	red25: '#EFC5C9',

	productCard: '#F7F7F5',
};

// Override automatic text color assignment (which works 90% of the time) and
// assign custom text colors to specific theme colors.
defineCustomTextColors({
	[palette.cement100]: 'white',
});

export const colorsAj: ColorsType = {
	...colorsBase,
	black: palette.black,
	white: palette.white,

	primary: palette.black85,
	secondary: palette.cement100,

	buttonDark: palette.black,
	buttonLight: palette.white,

	productDetailsBackground: palette.sand25,

	productCardThumbnail: palette.productCard,

	metaMenuDefaultBackgroundColor: palette.sand25,
};
