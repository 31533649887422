import { ColorsType } from '~/theme/model/themeModel';
import { defineCustomTextColors } from '~/theme/utils/getTextColor';
import * as colorsBase from './colorsBase';

export const palette = {
	// Primary
	black: '#222222',
	white: '#FEFEFE',

	asphalt100: '#B6A060',
	asphalt50: '#A5A5A5',
	asphalt25: '#D2D2D2',

	sand100: '#EAE7E2',
	sand50: '#F4F3F0',
	sand25: '#FAF9F8',

	cement100: '#AFA9A0',
	cement50: '#D7D4CF',
	cement25: '#EBE9E7',

	dirt100: '#8C857B',
	dirt50: '#C5C2BD',
	dirt25: '#E2E0DE',

	clay100: '#C4BCB7',
	clay50: '#E1DDDB',
	clay25: '#F0EEED',

	brown100: '#857874',
	brown50: '#C2BBB9',
	brown25: '#E0DDDC',

	sky100: '#888B8D',
	sky50: '#C3C5C6',
	sky25: '#E1E2E2',

	productCard: '#F7F7F5',
};

// Override automatic text color assignment (which works 90% of the time) and
// assign custom text colors to specific theme colors.
defineCustomTextColors({
	[palette.dirt100]: 'white',
	[palette.brown100]: 'white',
});

export const colorsLyp: ColorsType = {
	...colorsBase,
	black: palette.black,
	white: palette.white,

	primary: palette.asphalt100,
	secondary: palette.sand100,

	buttonDark: palette.black,
	buttonLight: palette.white,

	productDetailsBackground: palette.sand25,

	productCardThumbnail: palette.productCard,

	bodyText: palette.black,

	metaMenuDefaultBackgroundColor: palette.sand25,
};
