import styled from '@emotion/styled';
import { transparentize } from 'color2k';
import Logo from '~/assets/logos/logo.svg';
import { ButtonProps } from '~/shared/components/Button/Button';
import { getButtonBaseFontStyle, StyledResetButton } from '~/shared/components/Button/styled';
import Link from '~/shared/components/Link/Link';
import { GlobalStyles } from '~/theme';
import { themeBase } from '~/theme/lib/themes/themeBase';

const StyledLogo = styled(Logo)({
	width: '100%',
	maxWidth: '200px',
	marginBottom: themeBase.space[4],
});

const StyledHeadline = styled.h1({
	...themeBase.textStyles.display2,
	marginBottom: themeBase.space[3],
});

const StyledParagraph = styled.p({
	...themeBase.textStyles.body,

	'& + &': {
		marginTop: themeBase.space[2],
	},
});

const StyledErrorContainer = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	minHeight: '100vh',
	paddingLeft: themeBase.space[4],
	paddingRight: themeBase.space[4],
});

const StyledErrorContent = styled.main({
	width: '100%',
	maxWidth: '800px',
});

export type StyledButtonProps = Omit<ButtonProps, 'icon' | 'iconAlignment' | 'iconLabel' | 'iconOffsetEdge'>;

export const StyledButton = styled(StyledResetButton)<StyledButtonProps>(() => getButtonBaseFontStyle({ theme: themeBase }), {
	'--button-fill': themeBase.colors.buttonDark,
	'--button-fill-transparent': transparentize(themeBase.colors.buttonDark, 1),
	'--button-text': themeBase.colors.buttonLight,
	'--button-pad-horizontal': themeBase.space[4],
	'--button-pad-vertical': themeBase.space[1],
	position: 'relative',
	display: 'inline-flex',
	justifyContent: 'center',
	alignItems: 'center',
	gap: themeBase.space[1],
	margin: '0',
	minHeight: themeBase.button.height,
	padding: 'var(--button-pad-vertical) var(--button-pad-horizontal)',
	textAlign: 'center',
	overflow: 'hidden',
	transition: `color ${themeBase.animations.timingShort}`,
	color: 'var(--button-text)',
	backgroundColor: 'transparent',
	boxShadow: 'inset 0 0 0 1px var(--button-fill)',
	marginTop: themeBase.space[5],

	'&:focus, &:hover': {
		textDecoration: 'none',
		color: 'var(--button-fill)',

		'&:before': {
			transitionDuration: themeBase.animations.timingShort,
			transform: 'translateY(50%)',
		},
	},

	'&:before': {
		content: '""',
		position: 'absolute',
		left: 0,
		right: 0,
		top: '-100%',
		bottom: '0',
		transition: `transform ${themeBase.animations.timingMedium} ${themeBase.animations.easeSmooth}`,
		pointerEvents: 'none',
		background: 'linear-gradient(to top, var(--button-fill) 0%, var(--button-fill) 50%, var(--button-fill-transparent) 50%, var(--button-fill-transparent) 100%)',
	},
});

export const StyledButtonContent = styled.span({
	position: 'relative',
	zIndex: 1,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	gap: themeBase.space[1],
});

export const ErrorFallback = () => {
	return (
		<>
			<GlobalStyles />
			<StyledErrorContainer>
				<StyledErrorContent>
					<Link
						href="/"
						isCustomStyle
					>
						<StyledLogo />
					</Link>
					<StyledHeadline>500 - Internal Server Error</StyledHeadline>
					<StyledParagraph>Something went wrong.</StyledParagraph>
					<StyledParagraph>Try reloading the page or contact us on the following email if the problem persists:</StyledParagraph>
					<StyledParagraph>
						<Link
							href="mailto:webshop@rdg.dk"
							isExternal
						>
							webshop@rdg.dk
						</Link>
					</StyledParagraph>
					<Link href="/">
						<StyledButton>
							<StyledButtonContent>Go to front page</StyledButtonContent>
						</StyledButton>
					</Link>
				</StyledErrorContent>
			</StyledErrorContainer>
		</>
	);
};
