import { useTheme } from '@emotion/react';
import { useMemo } from 'react';
import { getTextColor } from '../utils/getTextColor';

export const useModuleColors = (backgroundColor?: string) => {
	const theme = useTheme();
	const textColor = useMemo(() => getTextColor(theme, backgroundColor), [theme, backgroundColor]);

	return { backgroundColor, textColor };
};
