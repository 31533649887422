import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import NextLink from 'next/link';
import { CSSProperties } from 'react';
import { StyledRichText } from '../RawHtml/styled';

export type StyledLinkProps = {
	weight?: 'medium' | 'regular';
	alignment?: CSSProperties['textAlign'];
};

const linkStyle = ({ theme, alignment, weight }: StyledLinkProps & { theme: Theme }) => css`
	text-decoration: none;
	color: inherit;
	${alignment ? `textAlign: ${alignment};` : ''},
	${weight && theme.fontWeights ? `fontWeight: ${theme.fontWeights[weight]};` : ''},

	${StyledRichText} & {
		text-decoration: underline;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}
`;

export const StyledLink = styled.a<StyledLinkProps>(linkStyle);
export const StyledNextLink = styled(NextLink)<StyledLinkProps>(linkStyle);
