import { ColorsType } from '~/theme/model/themeModel';
import { defineCustomTextColors } from '~/theme/utils/getTextColor';
import * as colorsBase from './colorsBase';

export const palette = {
	black: '#191919',
	white: '#FFFFFF',

	darkNight: '#888B8D',
	dustyNight: '#97999B',
	coolNight: '#BBBCBC',
	lightNight: '#F3F3F3',

	darkEvening: '#081F2C',
	dustyEvening: '#3B4559',
	coolEvening: '#647692',

	darkEarlyEve: '#63899A',
	dustyEarlyEve: '#9EA6B4',
	coolEarlyEve: '#C6D6E3',

	darkAfternoon: '#22372B',
	dustyAfternoon: '#708573',
	coolAfternoon: '#9CAF88',

	darkEarlyDay: '#B9A568',
	dustyEarlyDay: '#CEB888',
	coolEarlyDay: '#D6D2C4',

	darkDawn: '#AB5C57',
	dustyDawn: '#CA9A8E',
	coolDawn: '#DDBCB0',
};

// Override automatic text color assignment (which works 90% of the time) and
// assign custom text colors to specific theme colors.
defineCustomTextColors({
	[palette.darkAfternoon]: 'white',
	[palette.darkEarlyDay]: 'white',
	[palette.darkNight]: 'white',
	[palette.darkDawn]: 'white',
	[palette.darkEarlyEve]: 'white',
	[palette.dustyAfternoon]: 'white',
	[palette.coolAfternoon]: 'white',
});

export const colorsRos: ColorsType = {
	...colorsBase,
	black: palette.black,
	white: palette.white,

	primary: palette.darkEarlyEve,
	secondary: palette.darkEarlyDay,

	buttonDark: palette.black,
	buttonLight: palette.white,

	metaMenuDefaultBackgroundColor: palette.lightNight,
	productDetailsBackground: palette.lightNight,
};
