import { forwardRef, useMemo } from 'react';
import LinkExternal from '~/shared/components/Link/LinkExternal';
import LinkExternalProps from '~/shared/components/Link/LinkExternal.def';
import LinkInternal from '~/shared/components/Link/LinkInternal';
import LinkInternalProps from '~/shared/components/Link/LinkInternal.def';
import LinkProps from './Link.def';

const COMPOSITION_BUTTON_REGEX = /css-(.+)-(.+)?Button/;
export const LINK_IS_INTERNAL_REGEX = /^(\.+|#|(javascript|mailto|mail):|\/|(?!http)\w+)/;

const Link = forwardRef<HTMLAnchorElement, LinkProps>(({ href, isCustomStyle, isExternal, hreflang, ...props }, ref) => {
	const urlHref = typeof href === 'string' ? href : href?.href || '#';
	const { className = '' } = props;

	if (!props.hrefLang && hreflang) {
		props.hrefLang = hreflang;
	}

	const hasCustomStyles = useMemo(() => {
		// Use a PlainLink when composed with a button to prevent style conflicts
		return isCustomStyle || COMPOSITION_BUTTON_REGEX.test(className);
	}, [isCustomStyle, className]);

	return isExternal || !LINK_IS_INTERNAL_REGEX.test(urlHref) ? (
		<LinkExternal
			ref={ref}
			href={urlHref}
			isCustomStyle={hasCustomStyles}
			{...(props as Omit<LinkExternalProps, 'href' | 'isCustomStyle'>)}
		/>
	) : (
		<LinkInternal
			ref={ref}
			href={href}
			isCustomStyle={hasCustomStyles}
			{...(props as Omit<LinkInternalProps, 'href' | 'isCustomStyle'>)}
		/>
	);
});

export default Link;
