import { css } from '@emotion/react';

export const fontPath = '/assets/fonts/metropolis';
export const fontFace = css(`
	@font-face {
		font-family: 'Metropolis';
		src: url('${fontPath}/Metropolis-Light.otf');
		src: local('Metropolis-Light.otf') format('OpenType'),
			url('${fontPath}/Metropolis-Light.otf') format('OpenType');
		font-weight: 300;
		font-style: normal;
		font-display: swap;
	}
	@font-face {
		font-family: 'Metropolis';
		src: url('${fontPath}/Metropolis-LightItalic.otf');
		src: local('Metropolis-LightItalic.otf') format('OpenType'),
			url('${fontPath}/Metropolis-LightItalic.otf') format('OpenType');
		font-weight: 300;
		font-style: italic;
		font-display: swap;
	}
	@font-face {
		font-family: 'Metropolis';
		src: url('${fontPath}/Metropolis-Medium.otf');
		src: local('Metropolis-Medium.otf') format('OpenType'),
			url('${fontPath}/Metropolis-Medium.otf') format('OpenType');
		font-weight: 500;
		font-style: normal;
		font-display: swap;
	}
	@font-face {
		font-family: 'Metropolis';
		src: url('${fontPath}/Metropolis-MediumItalic.otf');
		src: local('Metropolis-MediumItalic.otf') format('OpenType'),
			url('${fontPath}/Metropolis-MediumItalic.otf') format('OpenType');
		font-weight: 500;
		font-style: italic;
		font-display: swap;
	}
	@font-face {
		font-family: 'Metropolis';
		src: url('${fontPath}/Metropolis-Regular.otf');
		src: local('Metropolis-Regular.otf') format('OpenType'),
			url('${fontPath}/Metropolis-Regular.otf') format('OpenType');
		font-weight: 400;
		font-style: normal;
		font-display: swap;
		font-style: normal;
	}
	@font-face {
		font-family: 'Metropolis';
		src: url('${fontPath}/Metropolis-RegularItalic.otf');
		src: local('Metropolis-RegularItalic.otf') format('OpenType'),
			url('${fontPath}/Metropolis-RegularItalic.otf') format('OpenType');
		font-weight: 400;
		font-style: italic;
		font-display: swap;
		font-style: italic;
	}
	@font-face {
		font-family: 'Metropolis';
		src: url('${fontPath}/Metropolis-SemiBold.otf');
		src: local('Metropolis-SemiBold.otf') format('OpenType'),
			url('${fontPath}/Metropolis-SemiBold.otf') format('OpenType');
		font-weight: 700;
		font-style: normal;
		font-display: swap;
	}
	@font-face {
		font-family: 'Metropolis';
		src: url('${fontPath}/Metropolis-SemiBoldItalic.otf');
		src: local('Metropolis-SemiBoldItalic.otf') format('OpenType'),
			url('${fontPath}/Metropolis-SemiBoldItalic.otf') format('OpenType');
		font-weight: 700;
		font-style: italic;
		font-display: swap;
	}
	@font-face {
		font-family: 'rdgsymbolsregular';
		src: url('/assets/fonts/rdgsymbols/RDGSymbols.ttf');
		font-display: block;
	}
`);
