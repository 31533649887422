import { useEffect, useState } from 'react';
import LinkIcon from '~/icons/chevron-up.svg';
import { StyledToTopButton } from './styled';

export const ScrollToTopButton = () => {
	const [isVisible, setVisible] = useState(false);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop;
		if (scrolled > 300) {
			setVisible(true);
		} else if (scrolled <= 300) {
			setVisible(false);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', toggleVisible);

		return function unMount() {
			window.removeEventListener('scroll', toggleVisible);
		};
	}, []);

	return (
		<StyledToTopButton
			onClick={scrollToTop}
			isVisible={isVisible}
		>
			<LinkIcon />
		</StyledToTopButton>
	);
};
