import { UnauthorizedError } from '~/features/checkout/service/UnauthorizedError'; // move this closer to fetcher
import { isBrowser } from '~/shared/utils/platform';

export const fetcher =
	<T>(url: string, options?: RequestInit) =>
		async () => {
			let init: RequestInit = {
				...options,
			};

			if (isBrowser) {
				init = {
					...init,
					headers: {
						...init.headers,
						'x-referrer': window.document.referrer,
					},
				};
			}

			const response = await fetch(url, init);

			// Handling unauthorized/forbidden (could be missing or expired token)
			if (response.status === 401 || response.status === 403) {
				throw new UnauthorizedError();
			}

			const contentType = response.headers.get('content-type') || '';
			if (!contentType.includes('application/json')) {
				throw Error(`Content type "${contentType}" handling not implemented`);
			}

			if (response.status !== 200) {
				throw Error(`Request failed to ${url}`);
			}

			const data: T = await response.json();
			return data;
		};
