import { ThemeType } from '../../model/themeModel';
import { breakpoints } from '../breakpoints';
import { colorsHo } from '../colors/colorsHo';
import { getFluidFontSize } from '../getClamp';
import { themeBase } from './themeBase';

export const fonts = {
	headline: 'Metropolis, serif',
	body: 'Metropolis, serif',
	mono: 'monospace',
};

export const fontSizes = {
	'4xs': getFluidFontSize(8, 10),
	'3xs': getFluidFontSize(10, 12),
	'2xs': getFluidFontSize(12, 14),
	xs: getFluidFontSize(14, 16),
	sm: getFluidFontSize(16, 18),
	md: getFluidFontSize(18),
	lg: getFluidFontSize(20),
	xl: getFluidFontSize(25),
	'2xl': getFluidFontSize(30),
	'3xl': getFluidFontSize(35),
	'4xl': getFluidFontSize(40),
	'5xl': getFluidFontSize(45),
	'6xl': getFluidFontSize(50),
	'7xl': getFluidFontSize(60),
};

export const fontWeights = {
	light: 300,
	regular: 400,
	medium: 500,
	semiBold: 700,
	bold: 700,
};

const lineHeights = {
	...themeBase.lineHeights,
	headline: '1.1',
	headlineTall: '1.2',
	body: '1.66666667',
	base: '1.75',
};

const bodyStyles: Record<any, any> = {
	fontFamily: fonts.body,
};

const headlineStyles: Record<any, any> = {
	fontFamily: fonts.headline,
	lineHeight: lineHeights.headline,
	textTransform: 'uppercase',
};

/**
 * Arne Jacobsen theme
 */
export const themeHo: ThemeType = {
	...themeBase,
	id: 'ho',
	colors: colorsHo,
	fonts,
	fontSizes,
	lineHeights,
	fontWeights,
	textStyles: {
		...themeBase.textStyles,
		body: {
			...bodyStyles,
			lineHeight: lineHeights.body,
			fontSize: getFluidFontSize(18, 20),
		},
		bodySm: {
			...bodyStyles,
			lineHeight: lineHeights.base,
			fontSize: getFluidFontSize(16, 18),
		},
		bodyXs: {
			...bodyStyles,
			lineHeight: '1.42857143',
			fontSize: getFluidFontSize(14, 16),
		},
		label: {
			fontFamily: fonts.body,
			fontSize: getFluidFontSize(14, 16),
			letterSpacing: '0.00714286em',
			lineHeight: lineHeights.single,
		},
		display1: {
			...headlineStyles,
			fontSize: '35px',
			letterSpacing: '6px',

			[breakpoints.sm]: {
				fontSize: '50px',
				letterSpacing: '8px',
			},
			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(60),
				letterSpacing: '0.13333333em', // Should equal 8px on desktop breakpoint
			},
		},
		display2: {
			...headlineStyles,
			fontSize: '30px',
			letterSpacing: '5px',

			[breakpoints.sm]: {
				fontSize: '35px',
				letterSpacing: '6px',
			},
			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(40),
				letterSpacing: '0.125em', // Should equal 5px on desktop breakpoint
			},
		},
		display3: {
			...headlineStyles,
			fontSize: '25px',
			letterSpacing: '3px',

			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(30),
				letterSpacing: '0.13333333em', // Should equal 4px on desktop breakpoint
			},
		},
		display3Push: {
			...headlineStyles,
			fontSize: getFluidFontSize(25, 29),
			letterSpacing: '0.02em',
		},
		display4: {
			...headlineStyles,
			fontSize: '20px',
			letterSpacing: '2.2px',

			[breakpoints.lg]: {
				fontSize: getFluidFontSize(25),
				letterSpacing: '0.1em',
			},
		} as Record<any, any>,
		display5: {
			...headlineStyles,
			fontSize: '18px',
			letterSpacing: '2px',

			[breakpoints.lg]: {
				fontSize: getFluidFontSize(20),
				letterSpacing: '0.1em',
			},
		} as Record<any, any>,
	},
	button: {
		...themeBase.button,
		fontFamily: fonts.body,
		fontWeight: fontWeights.semiBold,
		letterSpacing: '0.08357143em',
	},
};
