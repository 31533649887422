import { Theme } from '@emotion/react';
import { getContrast } from 'color2k';

let customTextColors: { [color: string]: 'black' | 'white' } = {};

/**
 * Define custom text color when automatic black/white isn't sufficient.
 *
 * Useful to override the default text color automation (that uses TEXT_LUMINANCE_THRESHOLD)
 * and forego useability standards for aeshetics.
 */
export const defineCustomTextColors = (textColorPairs: { [color: string]: 'black' | 'white' }) => {
	customTextColors = {
		...customTextColors,
		...textColorPairs,
	};
};

const BACKGROUND_COLOR_REGEX = /^#......00$/;

export const getTextColor = (theme: Theme, backgroundColor?: string) => {
	if (!backgroundColor || ['transparent', 'none'].includes(backgroundColor) || BACKGROUND_COLOR_REGEX.test(backgroundColor)) {
		return theme.colors.black;
	}

	if (typeof customTextColors[backgroundColor] === 'string') {
		return customTextColors[backgroundColor] === 'black' ? theme.colors.black : theme.colors.white;
	}

	return getContrast(backgroundColor, theme.colors.black) > getContrast(backgroundColor, theme.colors.white) ? theme.colors.black : theme.colors.white;
};
