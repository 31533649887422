import { ThemeType } from '../../model/themeModel';
import { breakpoints } from '../breakpoints';
import { colorsAj } from '../colors/colorsAj';
import { getFluidFontSize } from '../getClamp';
import { themeBase } from './themeBase';

const { textStyles } = themeBase;

const fonts = {
	headline: `'Source Sans Pro', sans-serif`,
	body: `'Source Sans Pro', sans-serif`,
	mono: `'Source Sans Pro', sans-serif`,
};

export const fontWeights = {
	light: 300,
	regular: 400,
	medium: 600,
	semiBold: 700,
	bold: 700,
};

const lineHeights = {
	...themeBase.lineHeights,
	headline: '1.1',
	headlineTall: '1.2',
	body: '1.66666667',
	base: '1.66666667',
};

const bodyStyles: Record<any, any> = {
	fontFamily: fonts.body,
	lineHeight: lineHeights.body,
	fontWeight: fontWeights.regular,
};

const headlineStyles: Record<any, any> = {
	fontFamily: fonts.headline,
	lineHeight: lineHeights.headline,
	fontWeight: fontWeights.light,
	textTransform: 'uppercase',
};

/**
 * Arne Jacobsen theme
 */
export const themeAj: ThemeType = {
	...themeBase,
	id: 'aj',
	colors: colorsAj,
	fonts,
	lineHeights,
	fontWeights,
	textStyles: {
		...textStyles,
		body: {
			...bodyStyles,
			fontSize: getFluidFontSize(18, 20),
		},
		bodySm: {
			...bodyStyles,
			fontSize: getFluidFontSize(16, 18),
		},
		bodyXs: {
			...bodyStyles,
			lineHeight: '1.42857143',
			fontSize: getFluidFontSize(14, 16),
		},
		label: {
			...bodyStyles,
			fontSize: getFluidFontSize(16, 17),
			lineHeight: lineHeights.single,
		},
		display1: {
			...headlineStyles,
			fontSize: '35px',
			letterSpacing: '3.5px',

			[breakpoints.sm]: {
				fontSize: '50px',
				letterSpacing: '5px',
			},
			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(60),
				letterSpacing: '0.1em',
			},
		},
		display2: {
			...headlineStyles,
			fontSize: '30px',
			letterSpacing: '3px',

			[breakpoints.sm]: {
				fontSize: '35px',
				letterSpacing: '3.5px',
			},
			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(40),
				letterSpacing: '0.1em',
			},
		},
		display3: {
			...headlineStyles,
			fontSize: '25px',
			letterSpacing: '2.5px',

			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(30),
				letterSpacing: '0.1em',
			},
		},
		display3Push: {
			...headlineStyles,
			fontWeight: fontWeights.regular,
			fontSize: '22px',
			letterSpacing: '2.2px',

			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(25, 29),
				letterSpacing: '0.1em',
			},
		},
		display4: {
			...headlineStyles,
			fontSize: '20px',
			letterSpacing: '2.2px',

			[breakpoints.lg]: {
				fontSize: getFluidFontSize(25),
				letterSpacing: '0.1em',
			},
		} as Record<any, any>,
		display5: {
			...headlineStyles,
			fontSize: '18px',
			letterSpacing: '2px',

			[breakpoints.lg]: {
				fontSize: getFluidFontSize(20),
				letterSpacing: '0.1em',
			},
		} as Record<any, any>,
	},
	button: {
		...themeBase.button,
		fontFamily: fonts.body,
		fontWeight: fontWeights.regular,
		letterSpacing: 'normal',
		fontSize: getFluidFontSize(16, 17),
		fontSizeSmall: getFluidFontSize(12, 15),
	},
};
