import { useRouter } from 'next/router';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { usePreviousDistinct, useSessionStorage, useUpdateEffect } from 'react-use';
import { IPages } from '~/lib/data-contract';
import { usePage } from '~/templates/pages/hooks/usePage';
import { useMarket } from '../../market/hooks/useMarket';

type RouteHistoryProps = { routeStack: string[]; previousRoute?: string; previousPage?: IPages };

export const RouteHistoryContext = createContext<RouteHistoryProps>({
	routeStack: [],
	previousRoute: undefined,
	previousPage: undefined,
});

const PREVIOUS_PAGE_SESSION_STORAGE_KEY = 'RDG_PREVIOUS_PAGE';

export const RouteHistoryProvider = ({ children }: { children: ReactNode }) => {
	const router = useRouter();
	const { market } = useMarket();
	const currentPage = usePage();
	const previousPage = usePreviousDistinct(currentPage);
	// we use session storage to persist the state between a full refresh.
	const storage = useSessionStorage<IPages>(PREVIOUS_PAGE_SESSION_STORAGE_KEY);

	const [persistedPreviousPage, setPersistedPreviousPage] = storage;
	const [routeStack, setRouteStack] = useState<string[]>([router.asPath]);

	useUpdateEffect(() => {
		setTimeout(() => setRouteStack([router.asPath]), 0); // Clear stack after changing market
	}, [market]);

	useUpdateEffect(() => {
		setRouteStack((prevRouteStack) => [...prevRouteStack, router.asPath]);
	}, [router.asPath]);

	useEffect(() => {
		if (previousPage) {
			setPersistedPreviousPage(previousPage);
		}
	}, [previousPage]);

	return (
		<RouteHistoryContext.Provider
			value={{
				routeStack,
				previousRoute: routeStack.length >= 1 ? routeStack[routeStack.length - 2] : undefined,
				previousPage: previousPage || persistedPreviousPage,
			}}
		>
			{children}
		</RouteHistoryContext.Provider>
	);
};
