import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { darken, lighten, transparentize } from 'color2k';
import { ifNotProp, ifProp, switchProp } from 'styled-tools';
import { ButtonProps } from './Button';

export const StyledResetButton = styled.button({
	alignItems: 'center',
	appearance: 'none',
	border: 'none',
	display: 'inline-flex',
	flexShrink: 0,
	lineHeight: 1,
	letterSpacing: 0,
	margin: 0,
	padding: 0,
	textRendering: 'inherit',
	textDecoration: 'none',
	WebkitUserSelect: 'none',
	userSelect: 'none',
	WebkitTapHighlightColor: 'rgba(0,0,0,0)',
	cursor: 'pointer',
	borderRadius: 0,
	backgroundColor: 'transparent',
	color: 'inherit',
	outlineOffset: '2px',

	'@media not all and (any-hover: hover)': {
		outline: 'none', // hide the outline on devices that have no hover
	},
});

export const getButtonBaseFontStyle = ({ theme, small }: { theme: Theme; small?: boolean }) =>
	css({
		fontSize: small ? theme.button.fontSizeSmall : theme.button.fontSize,
		fontFamily: theme.button.fontFamily,
		fontWeight: theme.button.fontWeight,
		letterSpacing: theme.button.letterSpacing,
		textTransform: theme.button.textTransform,
	});

export type StyledButtonProps = Omit<ButtonProps, 'icon' | 'iconAlignment' | 'iconLabel' | 'iconOffsetEdge'>;

const getButtonColor = (theme: Theme, shade: ButtonProps['shade'], translucent?: ButtonProps['translucent']) => {
	if (shade === 'light') {
		return translucent ? darken(theme.colors.buttonLight, 0.15) : theme.colors.buttonLight;
	}

	return translucent ? lighten(theme.colors.buttonDark, 0.15) : theme.colors.buttonDark;
};

export const StyledButton = styled(StyledResetButton)<StyledButtonProps>(
	getButtonBaseFontStyle,
	({ theme, shade, translucent, textAlignment, small }) => ({
		'--button-fill': getButtonColor(theme, shade, translucent),
		'--button-fill-transparent': transparentize(getButtonColor(theme, shade, translucent), 1),
		'--button-text': getButtonColor(theme, shade === 'light' ? 'dark' : 'light'),
		'--button-pad-horizontal': small ? theme.space[2] : theme.space[4],
		'--button-pad-vertical': theme.space[1],
		position: 'relative',
		display: 'inline-flex',
		justifyContent: textAlignment === 'left' ? 'flex-start' : 'center',
		alignItems: 'center',
		gap: theme.space[1],
		margin: '0',
		minHeight: small ? theme.button.heightSmall : theme.button.height,
		padding: 'var(--button-pad-vertical) var(--button-pad-horizontal)',
		textAlign: 'center',
		overflow: 'hidden',
		transition: `color ${theme.animations.timingShort}`,

		'&:focus, &:hover': {
			textDecoration: 'none',
			color: 'inherit',
		},
	}),

	ifProp('disabled', ({ theme, variant }) => ({
		'--button-fill': variant === 'filled' ? theme.colors.grey40 : theme.colors.grey50,
		'--button-text': theme.colors.grey50,
		pointerEvents: 'none',
	})),
	ifNotProp({ variant: 'plain' }, ({ theme }) => ({
		'&:before': {
			content: '""',
			position: 'absolute',
			left: 0,
			right: 0,
			top: '-100%',
			bottom: '0',
			transition: `transform ${theme.animations.timingMedium} ${theme.animations.easeSmooth}`,
			pointerEvents: 'none',
		},

		'&:focus, &:hover': {
			textDecoration: 'none',
			color: 'inherit',

			'&:before': {
				transitionDuration: theme.animations.timingShort,
				transform: 'translateY(50%)',
			},
		},
	})),

	switchProp(
		'variant',
		{
			plain: ({ theme, disabled }) => ({
				'--button-pad-horizontal': theme.space[1],
				'--button-pad-vertical': theme.space[1],
				minHeight: 'auto',
				margin: 'calc(var(--button-pad-vertical) * -1) calc(var(--button-pad-horizontal) * -1)',
				padding: 'var(--button-pad-vertical) var(--button-pad-horizontal)',
				backgroundColor: 'transparent',
				color: disabled ? 'var(--button-text)' : 'var(--button-fill)',
				overflow: 'visible',

				'&:after': {
					content: '""',
					position: 'absolute',
					left: 'var(--button-pad-horizontal)',
					right: 'var(--button-pad-horizontal)',
					bottom: 'calc(var(--button-pad-vertical) * 0.5)',
					height: '1px',
					backgroundColor: 'currentColor',
					transform: 'scaleX(0)',
					transformOrigin: 'right center',
					transition: `transform ${theme.animations.timingLong} ${theme.animations.easeOutCirc}`,
				},

				'&:focus:after, &:hover:after': {
					transform: 'scaleX(1)',
					transformOrigin: 'left center',
				},
			}),
			outline: {
				color: 'var(--button-fill)',
				backgroundColor: 'transparent',
				boxShadow: 'inset 0 0 0 1px var(--button-fill)',

				'&:before': {
					background: 'linear-gradient(to bottom, var(--button-fill) 0%, var(--button-fill) 50%, var(--button-fill-transparent) 50%, var(--button-fill-transparent) 100%)',
				},

				'&:focus, &:hover': {
					color: 'var(--button-text)',
				},
			},
		},
		{
			// "filled", default case
			color: 'var(--button-text)',
			backgroundColor: 'transparent',
			boxShadow: 'inset 0 0 0 1px var(--button-fill)',

			'&:before': {
				background: 'linear-gradient(to top, var(--button-fill) 0%, var(--button-fill) 50%, var(--button-fill-transparent) 50%, var(--button-fill-transparent) 100%)',
			},

			'&:focus, &:hover': {
				color: 'var(--button-fill)',
			},
		},
	),
);

export const StyledButtonContent = styled.span(({ theme }) => ({
	position: 'relative',
	zIndex: 1,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	gap: theme.space[1],
}));
