import { ColorsType } from '~/theme/model/themeModel';
import * as colorsBase from './colorsBase';

export const palette = {
	black: '#222222',
	white: '#FEFEFE',

	jord100: '#7B7167',

	rug100: '#C4B7AA',

	havre100: '#CBAF92',

	hvede100: '#F2DFBF',
	hvede25: '#F3F1EE',

	hav100: '#93A6B2',

	musling100: '#EBBBB1',

	productCard: '#F7F7F5',
};

export const colorsJun: ColorsType = {
	...colorsBase,
	black: palette.black,
	white: palette.white,

	primary: palette.jord100,
	secondary: palette.rug100,

	buttonDark: palette.black,
	buttonLight: palette.white,

	productDetailsBackground: palette.hvede25,

	productCardThumbnail: palette.productCard,

	metaMenuDefaultBackgroundColor: palette.hvede25,
};
