import { Router } from 'next/router';
import { Component, ErrorInfo, ReactNode } from 'react';
import { ErrorFallback } from './ErrorFallback';

type ErrorBoundaryProps = {
	children?: ReactNode | ReactNode[];
	router: Router;
};

type ErrorBoundaryState = {
	error: boolean;
};

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
	state = {
		error: false,
	};

	static getDerivedStateFromError() {
		return { error: true };
	}

	handleRouterChangeComplete = () => {
		if (this.state.error) {
			this.setState({
				error: false,
			});
		}
	};

	componentDidMount() {
		this.props.router.events.on('routeChangeComplete', this.handleRouterChangeComplete);
	}

	componentWillUnmount() {
		this.props.router.events.off('routeChangeComplete', this.handleRouterChangeComplete);
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error({ error, errorInfo });
	}

	render() {
		if (this.state.error) {
			return <ErrorFallback />;
		}

		return this.props.children;
	}
}
