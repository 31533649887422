import { css } from '@emotion/react';
import { breakpoints } from '~/theme';
import { gridConfig } from './themes/themeBase';

export const columnGlobals = css({
	html: {
		// Configure reactive variables to responsively change gutter widths
		'--grid-gutter-xs': gridConfig.gutter.xs,
		'--grid-gutter-md': gridConfig.gutter.md,
		'--grid-gutter': 'var(--grid-gutter-xs)',

		// Configure reactive variables to responsively change column count
		'--grid-column-count-xs': gridConfig.columns.xs,
		'--grid-column-count-md': gridConfig.columns.md,
		'--grid-column-count': 'var(--grid-column-count-xs)',

		// Subtract scrollbar width from view width. This is the base for our calculations
		'--grid-layout-width': 'calc(100vw - var(--scrollbar-width))',

		// Width of a column including gutter
		'--grid-column': 'calc((var(--grid-layout-width) + var(--grid-gutter)) / var(--grid-column-count))',

		// Width of a row is the same as a column, but with a max size
		'--grid-row': gridConfig.rows.xs,

		[`${breakpoints.md}`]: {
			// Assign reactive variables for larger devices
			'--grid-gutter': 'var(--grid-gutter-md)',
			'--grid-column-count': 'var(--grid-column-count-md)',
			'--grid-row': gridConfig.rows.md,
		},
	},
});

/**
 * Use this to get a column width to align our grid
 * @param amountOfColumns Amount of columns width.
 * @example
 *  paddingLeft: getColumn(1),
 *  marginTop: getColumn(-1),
 *
 * @param excludeGutter If you want to exclude the last gutter of the column span.
 * @example
 *  paddingLeft: getColumn(4),
 *  width: getColumn(4, true),
 */
export const getColumn = (amountOfColumns: number, excludeGutter = false): string => {
	if (amountOfColumns === 1 && !excludeGutter) {
		return 'var(--grid-column)';
	}
	return excludeGutter ? `calc(var(--grid-column) * ${amountOfColumns} - var(--grid-gutter))` : `calc(var(--grid-column) * ${amountOfColumns})`;
};

/**
 * Use this to get a row height to align our grid
 *
 * Rows are like columns, but cap at a max size. Useful for 2-dimensional layouts.
 *
 * @param amountOfRows Amount of rows.
 * @example
 *      paddingTop: getRow(1),
 *      marginTop: getRow(-1),
 */
export const getRow = (amountOfRows: number): string => {
	return amountOfRows === 1 ? 'var(--grid-row)' : `calc(var(--grid-row) * ${amountOfRows})`;
};

export const getGutter = () => `var(--grid-gutter)`;
