import { lgMinWidth, xlMinWidth } from './breakpoints';

/**
 * Use this to get fluid font size
 * @param targetValue the desired font size to display
 * @param maxSize the maximum font size to display
 */
export const getFluidFontSize = (targetValue: number, maxSize?: number): string => {
	const minSize = targetValue; // Min size for the font, which should be the value, from the design
	const fluidValue = (targetValue / lgMinWidth) * 100; // The fluid value between min value and max value
	const maxValue = maxSize ? maxSize : xlMinWidth * (fluidValue / 100); // Use the defined max value if it exists or the default max value

	return `clamp(${minSize}px, ${fluidValue}vw, ${Math.round(maxValue)}px)`;
};
