import { useRouter } from 'next/router';
import { forwardRef, MouseEvent, useCallback } from 'react';
import { GaContactEvent, GaOutgoingLinkEvent } from '~/features/tracking/model';
import { pushToDataLayer } from '~/features/tracking/utils/pushToDataLayer';
import { StyledLink } from './Link.styled';
import LinkExternalProps, { LinkExternalTagProps } from './LinkExternal.def';

const getUrl = (href: string): URL | null => {
	try {
		return new URL(href);
	} catch {
		return null;
	}
};

const LinkExternal = forwardRef<HTMLAnchorElement, LinkExternalProps>(({ onClick, target: propTarget, isCustomStyle, href: hrefProps, ...props }, ref) => {
	const router = useRouter();
	const href = typeof hrefProps === 'string' ? hrefProps : hrefProps.href || '';
	const url = getUrl(href);

	const handleClick = useCallback(
		(e: MouseEvent<HTMLAnchorElement>) => {
			if (!url) return; // Invalid URL, potentially internal link

			if (url.protocol === 'mailto:') {
				pushToDataLayer<GaContactEvent>({
					event: 'gaEvent',
					eventCategory: 'contact',
					eventAction: 'email_click',
					eventLabel: router.asPath,
				});
			} else if (url.protocol === 'tel:') {
				pushToDataLayer<GaContactEvent>({
					event: 'gaEvent',
					eventCategory: 'contact',
					eventAction: 'phone_click',
					eventLabel: router.asPath,
				});
			} else if (url.hostname.length && url.hostname !== window.location.hostname) {
				pushToDataLayer<GaOutgoingLinkEvent>({
					event: 'gaEvent',
					eventCategory: 'outgoing link',
					eventAction: href,
					eventLabel: router.asPath,
				});
			}

			onClick?.(e);
		},
		[onClick, href, url, router.asPath],
	);

	let target: LinkExternalProps['target'] = propTarget;
	if (!target && url && !['mailto:', 'tel:'].includes(url.protocol)) {
		target = '_blank';
	}

	const linkProps: LinkExternalTagProps = {
		...props,
		href: href,
		onClick: handleClick,
		target: target,
	};

	return isCustomStyle ? (
		<a
			ref={ref}
			{...linkProps}
		/>
	) : (
		<StyledLink
			ref={ref}
			{...linkProps}
		/>
	);
});

export default LinkExternal;
