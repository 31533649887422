import { ThemeType } from '../../model/themeModel';
import { breakpoints } from '../breakpoints';
import { colorsKah } from '../colors/colorsKah';
import { getFluidFontSize } from '../getClamp';
import { themeBase } from './themeBase';

export const fonts = {
	headline: 'Cormorant Garamond, Georgia, serif',
	body: 'Roboto, Arial, sans-serif',
	mono: 'monospace',
};

export const fontWeights = {
	light: 300,
	regular: 400,
	medium: 500,
	semiBold: 500,
	bold: 700,
};

const lineHeights = {
	...themeBase.lineHeights,
	headline: '1.1',
	headlineTall: '1.2',
	body: '1.66666667',
};

const headlineStyles = {
	fontFamily: fonts.headline,
	lineHeight: lineHeights.headline,
	textTransform: 'uppercase',
};

const bodyStyles = {
	fontFamily: fonts.body,
	lineHeight: lineHeights.body,
};

/**
 * Kähler theme
 */
export const themeKah: ThemeType = {
	...themeBase,
	id: 'kah',
	fonts,
	fontWeights,
	lineHeights,
	colors: colorsKah,
	textStyles: {
		...themeBase.textStyles,
		body: {
			...bodyStyles,
			fontSize: getFluidFontSize(18, 21),
		},
		bodySm: {
			...bodyStyles,
			fontSize: getFluidFontSize(16, 18),
		},
		bodyXs: {
			...bodyStyles,
			fontSize: getFluidFontSize(14, 16),
		},
		label: {
			...bodyStyles,
			lineHeight: lineHeights.single,
			fontWeight: fontWeights.medium,
			fontSize: getFluidFontSize(14, 16),
			textTransform: 'uppercase',
			letterSpacing: '0.03571429em',
		},
		display1: {
			fontFamily: headlineStyles.fontFamily,
			lineHeight: lineHeights.headline,
			fontWeight: fontWeights.bold,
			textTransform: 'uppercase',
			fontSize: '35px',
			letterSpacing: '6px',

			[breakpoints.sm]: {
				fontSize: '50px',
				letterSpacing: '8px',
			},
			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(60),
				letterSpacing: '0.16666667em', // Should equal 10px on desktop breakpoint
			},
		},
		display2: {
			fontFamily: headlineStyles.fontFamily,
			lineHeight: lineHeights.headline,
			fontWeight: fontWeights.bold,
			textTransform: 'uppercase',
			fontSize: '30px',
			letterSpacing: '5px',

			[breakpoints.sm]: {
				fontSize: '35px',
				letterSpacing: '5px',
			},
			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(40),
				letterSpacing: '0.175em', // Should equal 7px on desktop breakpoint
			},
		},
		display3: {
			fontFamily: headlineStyles.fontFamily,
			lineHeight: lineHeights.headline,
			fontWeight: fontWeights.bold,
			textTransform: 'uppercase',
			fontSize: '25px',
			letterSpacing: '3px',

			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(30),
				letterSpacing: '0.2em', // Should equal 6px on desktop breakpoint
			},
		},
		display3Push: {
			fontFamily: fonts.body,
			lineHeight: lineHeights.headline,
			fontWeight: fontWeights.regular,
			textTransform: 'uppercase',
			fontSize: getFluidFontSize(25),
			letterSpacing: '0.02em',
		},
		display4: {
			fontFamily: headlineStyles.fontFamily,
			lineHeight: lineHeights.headline,
			fontWeight: fontWeights.bold,
			textTransform: 'uppercase',
			fontSize: '20px',
			letterSpacing: '0.25px',

			[breakpoints.lg]: {
				fontSize: getFluidFontSize(25),
				letterSpacing: '0.2em',
			},
		},
		display5: {
			fontFamily: headlineStyles.fontFamily,
			lineHeight: lineHeights.headline,
			fontWeight: fontWeights.bold,
			textTransform: 'uppercase',
			fontSize: '18px',
			letterSpacing: '0.2px',

			[breakpoints.lg]: {
				fontSize: getFluidFontSize(20),
				letterSpacing: '0.2em',
			},
		},
	},
	button: {
		...themeBase.button,
		fontFamily: fonts.body,
	},
	productDetails: {
		...themeBase.productDetails,
		carousel: {
			...themeBase.productDetails.carousel,
			paginationType: 'circle',
		},
	},
};
