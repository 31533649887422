import { createContext, PropsWithChildren } from 'react';
import { IMarket } from '../model/marketModel';

export const MarketContext = createContext<IMarket>({} as IMarket);

interface IMarketProviderProps {
	value: IMarket;
}

export const MarketProvider = ({ children, value }: PropsWithChildren<IMarketProviderProps>) => {
	return <MarketContext.Provider value={value}>{children}</MarketContext.Provider>;
};
