import { ThemeType } from '../../model/themeModel';
import { breakpoints } from '../breakpoints';
import { colorsJun } from '../colors/colorsJun';
import { getFluidFontSize } from '../getClamp';
import { themeBase } from './themeBase';

const fonts = {
	headline: 'Overpass, Arial, sans-serif',
	body: 'Overpass, Arial, sans-serif',
	mono: 'Overpass, Arial, sans-serif',
};

export const fontWeights = {
	light: 400,
	regular: 500,
	medium: 500,
	semiBold: 600,
	bold: 700,
};

const lineHeights = {
	...themeBase.lineHeights,
	headline: '1.1',
	headlineTall: '1.2',
	medium: '1.25',
	body: '1.66666667',
	base: '1.66666667',
};

const bodyStyles: Record<any, any> = {
	fontFamily: fonts.body,
	lineHeight: lineHeights.body,
	fontWeight: fontWeights.regular,
};

const headlineStyles: Record<any, any> = {
	fontFamily: fonts.headline,
	lineHeight: lineHeights.headline,
	fontWeight: fontWeights.semiBold,
	textTransform: 'none',
};

const { textStyles } = themeBase;

/**
 * Juna theme
 */
export const themeJun: ThemeType = {
	...themeBase,
	id: 'jun',
	fonts,
	lineHeights,
	fontWeights,
	colors: colorsJun,
	textStyles: {
		...textStyles,
		body: {
			...bodyStyles,
			fontSize: getFluidFontSize(18, 20),
		},
		bodySm: {
			...bodyStyles,
			fontSize: getFluidFontSize(16, 18),
		},
		bodyXs: {
			...bodyStyles,
			fontSize: getFluidFontSize(14, 16),
		},
		label: {
			...bodyStyles,
			fontSize: getFluidFontSize(16, 17),
			lineHeight: lineHeights.single,
		},
		display1: {
			...headlineStyles,
			fontSize: '35px',
			letterSpacing: '0.3px',

			[breakpoints.sm]: {
				fontSize: '50px',
				letterSpacing: '0.4px',
			},
			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(60, 66),
				letterSpacing: '0.00666667em',
			},
		},
		display2: {
			...headlineStyles,
			fontSize: '30px',
			letterSpacing: '0.25px',

			[breakpoints.sm]: {
				fontSize: '35px',
				letterSpacing: '0.3px',
			},
			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(40, 46),
				letterSpacing: '0.0075em',
			},
		},
		display3: {
			...headlineStyles,
			fontSize: '25px',
			letterSpacing: '0.2px',

			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(30, 36),
				letterSpacing: '0.00666667em',
			},
		},
		display3Push: {
			...headlineStyles,
			fontWeight: fontWeights.bold,
			fontSize: '22px',
			letterSpacing: '0.2px',

			// We only define responsive styles for the desktop
			[breakpoints.lg]: {
				fontSize: getFluidFontSize(25, 29),
				letterSpacing: '0.004em',
			},
		},
		display4: {
			...headlineStyles,
			fontSize: '20px',
			letterSpacing: '0.15px',

			[breakpoints.lg]: {
				fontSize: getFluidFontSize(25),
				letterSpacing: '0.00666667em',
			},
		} as Record<any, any>,
		display5: {
			...headlineStyles,
			fontSize: '18px',
			letterSpacing: '0.1px',

			[breakpoints.lg]: {
				fontSize: getFluidFontSize(20),
				letterSpacing: '0.00666667em',
			},
		} as Record<any, any>,
	},
	button: {
		...themeBase.button,
		fontFamily: fonts.body,
		fontWeight: fontWeights.semiBold,
		letterSpacing: 'normal',
		fontSize: '16px',
		fontSizeSmall: '12px',
		textTransform: 'none',
	},
};
