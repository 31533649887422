import styled from '@emotion/styled';
import { ifNotProp, ifProp, switchProp } from 'styled-tools';
import { TextProps } from './Text';

export const StyledText = styled.p<TextProps>(
	{
		img: {
			maxWidth: '100%',
			height: 'auto',
		},
	},
	ifNotProp('variant', ({ theme }) => ({
		...theme.textStyles.body,
	})),
	ifProp('alignment', ({ alignment }) => ({
		textAlign: alignment,
	})),
	switchProp('as', {
		i: {
			fontStyle: 'italic',
		},
		em: {
			fontStyle: 'italic',
		},
		strong: ({ theme }) => ({
			fontWeight: theme.fontWeights.bold,
		}),
		b: ({ theme }) => ({
			fontWeight: theme.fontWeights.bold,
		}),
	}),
	switchProp('variant', {
		caption: ({ theme }) => ({
			fontSize: theme.fontSizes['2xs'],
			color: theme.colors.grey30,
			letterSpacing: 0.55,
		}),
		label: ({ theme }) => ({
			...theme.textStyles.label,
			display: 'block',
		}),
		display1: ({ theme }) => ({
			...theme.textStyles.display1,
		}),
		display2: ({ theme }) => ({
			...theme.textStyles.display2,
		}),
		display3: ({ theme }) => ({
			...theme.textStyles.display3,
		}),
		display3Push: ({ theme }) => ({
			...theme.textStyles.display3Push,
		}),
		display4: ({ theme }) => ({
			...theme.textStyles.display4,
		}),
		display5: ({ theme }) => ({
			...theme.textStyles.display5,
		}),
		display6: ({ theme }) => ({
			...theme.textStyles.display6,
		}),
		body: ({ theme }) => ({
			...theme.textStyles.body,
		}),
		bodySm: ({ theme }) => ({
			...theme.textStyles.bodySm,
		}),
		bodySmBold: ({ theme }) => ({
			...theme.textStyles.bodySm,
			fontWeight: theme.fontWeights.bold,
		}),
		bodyXs: ({ theme }) => ({
			...theme.textStyles.bodyXs,
		}),
		bodyXsBold: ({ theme }) => ({
			...theme.textStyles.bodyXs,
			fontWeight: theme.fontWeights.bold,
		}),
		bodyBold: ({ theme }) => ({
			...theme.textStyles.body,
			fontWeight: theme.fontWeights.bold,
		}),
	}),
	({ theme, marginBottom, marginTop }) => ({
		marginBottom: marginBottom ? theme.space[marginBottom] : 0,
		marginTop: marginTop ? theme.space[marginTop] : 0,
	}),
);
