import { ColorsType } from '~/theme/model/themeModel';
import { defineCustomTextColors } from '~/theme/utils/getTextColor';
import * as colorsBase from './colorsBase';

export const palette = {
	// Primary
	black: '#222222',
	white: '#FEFEFE',

	// Gold
	gold100: '#B6A060',
	gold50: '#DACFAF',
	gold25: '#F5F3EB',

	// Sand
	sand100: '#C4BCB7',
	sand50: '#E1DDDB',
	sand25: '#F0EEED',

	// Maroon
	maroon100: '#512F2E',
	maroon50: '#A89796',
	maroon25: '#D3CBCB',

	// Fusia
	fusia100: '#86647A',
	fusia50: '#C2B1BC',
	fusia25: '#E1D8DE',

	// Rose
	rose100: '#C3B7BC',
	rose50: '#E1DBDD',
	rose25: '#F0EDEE',

	// Grey
	grey100: '#D9D9D6',
	grey50: '#ECECEA',
	grey25: '#F5F5F5',

	// Blue
	blue100: '#7A99AC',
	blue50: '#BCCCD5',
	blue25: '#DEE5EA',

	// Brown
	brown100: '#705853',
	brown50: '#B7ABA9',
	brown25: '#DBD5D4',

	// Beige
	beige100: '#947960',
	beige50: '#C9BCAF',
	beige25: '#E4DDD7',
};

// Override automatic text color assignment (which works 90% of the time) and
// assign custom text colors to specific theme colors.
defineCustomTextColors({
	[palette.gold100]: 'white',
	[palette.blue100]: 'white',
});

export const colorsHo: ColorsType = {
	...colorsBase,
	black: palette.black,
	white: palette.white,

	primary: palette.gold100,
	secondary: palette.sand100,

	buttonDark: palette.maroon100,
	buttonLight: palette.white,

	productDetailsBackground: palette.sand25,

	bodyText: palette.black,

	pageLoadingColor: palette.maroon100,

	metaMenuDefaultBackgroundColor: palette.sand25,
};
