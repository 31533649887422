import { useMemo } from 'react';
import { QueryKey, useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from 'react-query';
import { buildURL, QueryParams } from '../utils/buildURL';
import { fetcher } from '../utils/fetcher';
import { requestDefaults } from '../utils/requestDefaults';

type Mutate<T> = (newValue: T, invalidateQuery: boolean) => void;
export type Invalidate = () => void;

export type UseGetRequestResult<T, E> = UseQueryResult<T, E> & {
	isValidating: boolean;
	mutate: Mutate<T>;
	invalidate: Invalidate;
};

export type UseGetRequestOptions<T = unknown, E = unknown> = UseQueryOptions<T, E, T, QueryKey> & {
	params?: QueryParams;
};

/**
 * Helper method for useQuery (https://react-query.tanstack.com/reference/useQuery)
 *
 * Added:
 * - url + params are used as the key
 * - `mutation` helper method inspired by useSWR.
 * - `isValidating` helper valie inspired by useSWR
 * - `invalidate` helper method to invalidate and refetch data
 *
 * Using React Query over useSWR to future proff the codebase.
 * Read all available benenfits here:
 * NOTE: not all features are implemented yet.
 * @see https://react-query.tanstack.com/comparison
 */
export const useGetRequest = <T, E = unknown>(url: string, options?: UseGetRequestOptions<T, E>): UseGetRequestResult<T, E> => {
	const { params, cacheTime, ...otherOptions } = options || {};

	// Generate a determanistic URL based on url + parasm
	const serializedUrl = useMemo(() => {
		return buildURL(url, params);
	}, [url, params]);

	const queryKey = options?.queryKey || serializedUrl;
	const queryClient = useQueryClient();

	// Use determanistic generated URL as key by default
	const query = useQuery<T, E>(queryKey, fetcher<T>(serializedUrl), {
		cacheTime: cacheTime ?? requestDefaults.cacheTime,
		...otherOptions,
	});

	const isValidating = query.isFetching;

	// Helper method for invalidating
	const invalidate: Invalidate = () => queryClient.invalidateQueries(queryKey);

	// Helper method for mutating the stored state
	const mutate: Mutate<T> = (newValue, invalidateQuery = false) => {
		queryClient.setQueryData(queryKey, () => newValue);
		if (invalidateQuery) {
			invalidate();
		}
	};

	return {
		...query,
		isValidating,
		mutate,
		invalidate,
	};
};
