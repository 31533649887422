import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { ApplicationInsightsProvider } from '~/features/application-insights/components/ApplicationInsightsProvider';
import { reactPlugin } from '~/features/application-insights/init';
import { ErrorBoundary } from '~/features/error/components/ErrorBoundary';
import { ErrorFallback } from '~/features/error/components/ErrorFallback';
import { ScrollToTopButton } from '~/features/navigation/components/ScrollToTopButton/ScrollToTopButton';
import { CookieConsent } from '~/shared/components/CookieConsent/CookieConsent';
import { MarketProvider } from '~/shared/utils/market/context/MarketProvider';
import { IMarket } from '~/shared/utils/market/model/marketModel';
import { RequestProvider } from '~/shared/utils/request/context/RequestProvider';
import { RouteHistoryProvider } from '~/shared/utils/route-history/context/RouteHistoryProvider';
import { RouteTransitionProvider } from '~/shared/utils/route-transition/context/RouteTransitionProvider';
import { UiProvider } from '~/shared/utils/ui/context/UiProvider';
import { DynamicPageProps } from '~/templates/pages/components/DynamicPage/DynamicPage';
import { PageProvider } from '~/templates/pages/context/PageProvider';
import { ThemeProvider } from '~/theme';

type CustomAppProps<P = unknown> = {
	pageProps: P | undefined;
} & Omit<AppProps<P>, 'pageProps'>;

export const App = ({ Component: DynamicPage, pageProps, router }: CustomAppProps<Partial<DynamicPageProps>>): JSX.Element => {
	if (!pageProps || pageProps.hasError || !pageProps.data?.page) {
		return <ErrorFallback />;
	}

	const { page, dehydratedState } = pageProps.data;
	const { market, culture } = page;

	const currentMarket: IMarket = { market, culture };

	return (
		<ApplicationInsightsProvider plugin={reactPlugin}>
			<ErrorBoundary router={router}>
				<RequestProvider dehydratedState={dehydratedState}>
					<UiProvider>
						<PageProvider page={page}>
							<MarketProvider value={currentMarket}>
								<CookieConsent />
								<ThemeProvider>
									<RouteTransitionProvider>
										<RouteHistoryProvider>
											<DynamicPage {...pageProps} />
											<ScrollToTopButton />
										</RouteHistoryProvider>
									</RouteTransitionProvider>
								</ThemeProvider>
							</MarketProvider>
						</PageProvider>
					</UiProvider>
				</RequestProvider>
			</ErrorBoundary>
		</ApplicationInsightsProvider>
	);
};

export function reportWebVitals(metric: NextWebVitalsMetric): void {
	console.log('Webvitals', metric.name, Math.round(metric.value * 100) / 100, 'ms');
}

export default App;
