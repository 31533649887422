import NextLink from 'next/link';
import { forwardRef } from 'react';
import { StyledNextLink } from './Link.styled';
import LinkInternalProps from './LinkInternal.def';

const LinkInternal = forwardRef<HTMLAnchorElement, LinkInternalProps>(({ isCustomStyle, weight = 'medium', scroll = true, ...rest }, ref) => {
	return isCustomStyle ? (
		<NextLink
			ref={ref}
			scroll={scroll}
			{...rest}
		/>
	) : (
		<StyledNextLink
			ref={ref}
			weight={weight}
			scroll={scroll}
			{...rest}
		/>
	);
});

export default LinkInternal;
