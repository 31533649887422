import { ThemeType } from '../../model/themeModel';
import { themeBase } from './themeBase';

/**
 * Arne Jacobsen theme
 */
export const themeGlo: ThemeType = {
	...themeBase,
	id: 'glo',
};
