export const GOOGLE_TRACKING_ID = 'GTM-W8BNPG5';
export const ANONYMOUS_ID_COOKIE_NAME = 'AnonymousID';
export const DEVICE_ID_COOKIE_NAME = 'DeviceID';
export const RAPTOR_COOKIE_NAME = 'reaId';
export const SEARCH_LIST_NAME = 'search results page';
export const SEARCH_LIST_ID = '99999999';
export const BASKET_LIST_NAME = 'basket page';
export const BASKET_LIST_ID = '99999998';
export const PDP_LIST_ID = '99999997';
export const PDP_LIST_NAME = 'product detail page';
