import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ReactNode } from 'react';

type ApplicationInsightsProviderProps = {
	children: ReactNode | ReactNode[];
	plugin: ReactPlugin;
};

export const ApplicationInsightsProvider = ({ children, plugin }: ApplicationInsightsProviderProps) => {
	return <AppInsightsContext.Provider value={plugin}>{children}</AppInsightsContext.Provider>;
};
