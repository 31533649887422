import { ColorsType } from '~/theme/model/themeModel';
import { defineCustomTextColors } from '~/theme/utils/getTextColor';
import * as colorsBase from './colorsBase';

export const palette = {
	// Primary
	black: '#242629',
	light: '#EFF0EC',
	white: '#FEFEFE',

	ice100: '#B7C9D3',
	ice40: '#E2E9ED',
	ice20: '#F1F4F6',

	blue100: '#3B4557',
	blue40: '#B1B5BC',
	blue20: '#D8DADD',

	rose100: '#E7CEB5',
	rose40: '#F5EBE1',
	rose20: '#FAF5F0',

	sun100: '#CDA788',
	sun40: '#EBDCCF',
	sun20: '#F5EDE7',

	forest100: '#919388',
	forest40: '#D3D4CF',
	forest20: '#E9E9E7',

	earth100: '#978C87',
	earth40: '#D5D1CF',
	earth20: '#EAE8E7',
};

// Override automatic text color assignment (which works 90% of the time) and
// assign custom text colors to specific theme colors.
defineCustomTextColors({
	[palette.ice100]: 'white',
	[palette.rose100]: 'white',
	[palette.sun100]: 'white',
	[palette.forest100]: 'white',
	[palette.earth100]: 'white',
});

export const colorsKah: ColorsType = {
	...colorsBase,
	black: palette.black,
	white: palette.white,

	primary: palette.ice100,
	secondary: palette.blue100,

	buttonDark: palette.black,
	buttonLight: palette.white,

	productDetailsBackground: palette.earth20,

	bodyText: palette.black,

	pageLoadingColor: palette.blue100,

	metaMenuDefaultBackgroundColor: palette.earth20,
};
