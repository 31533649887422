import { themes } from '../lib/themes';
import { ColorsKeys } from '../model/themeModel';

/**
 * Helper method for using theme colors as a dropdown in storybook
 *
 * @example
 * ```ts
 * // example.stories.ts
 * export default {
 * 	// ...
 *  argTypes: {
 *      backgroundColor: {
 *          control: {
 *              type: 'color',
 *              presetColors: storybookPresetColors, // <<-- use storybookPresetColors here
 *          },
 *      },
 *  },
 * }
 * ```
 */
export const storybookPresetColors = Object.keys(themes['none'].colors).map((key) => ({
	title: key as ColorsKeys,
	color: themes['none'].colors[key as ColorsKeys],
}));

export const storybookThemeColorControl = {
	type: 'color',
	presetColors: storybookPresetColors,
};
